import React from 'react'
import NewTechnologies from './components/NewTechnologies'


export const Waf = () => {
  return (
    <>
      <div className='row d-flex flex-row  mt-5 '>
        <NewTechnologies />
      </div>
    </>
  )
}
