import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest } from 'redux-saga/effects'
// import {select} from 'redux-saga/effects'
import { UserModel } from '../models/UserModel'
import axios from 'axios'
// import {getUserByToken} from './AuthCRUD'
function generateExpireTime(time: number = 5) {
  const expireTime = new Date().getTime() + (time * 60 * 1000)
  return expireTime
}
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  SetDataDashboard: '[DATA] DASHBOARD',
  SetDataScope: '[DATA] SCOPE',
  SetDataAsset: '[DATA] ASSET',
  SetDataVulnerability: '[DATA] VULNERABILITY',
  SetDataSSL: '[DATA] SSL',
  SetDataDNS: '[DATA] DNS',
  SetDataSmtp: '[DATA] SMTP',
}
const data ={
  data: {
    dataDashboard: {
      data: undefined,
      expireTime: null
    },
    dataScope: {
      data: undefined,
      expireTime: null
    },
    dataAsset: {
      data: undefined,
      expireTime: null
    },
    dataVulnerability: {
      data: undefined,
      expireTime: null
    },
    dataDns: {
      data: undefined,
      expireTime: null
    },
    dataSSL: {
      data: undefined,
      expireTime: null
    },
    dataSmtp: {
      data: undefined,
      expireTime: null
    },
  },
  user: undefined,
  accessToken: undefined,
}
const initialAuthState: IAuthState = {
  data: {
    dataDashboard: {
      data: undefined,
      expireTime: null
    },
    dataScope: {
      data: undefined,
      expireTime: null
    },
    dataAsset: {
      data: undefined,
      expireTime: null
    },
    dataVulnerability: {
      data: undefined,
      expireTime: null
    },
    dataDns: {
      data: undefined,
      expireTime: null
    },
    dataSSL: {
      data: undefined,
      expireTime: null
    },
    dataSmtp: {
      data: undefined,
      expireTime: null
    },
  },
  user: undefined,
  accessToken: undefined,
}
interface DATA {
  data: undefined | any,
  expireTime: null | number
}
export interface IAuthState {
  data: {
    dataDashboard: DATA
    dataScope: DATA
    dataAsset: DATA
    dataVulnerability: DATA
    dataSSL: DATA
    dataDns: DATA
    dataSmtp: DATA
  }
  user: UserModel | undefined
  accessToken: string | undefined
}

export const reducer = persistReducer(
  { storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken'] },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        console.log('state',state)
        return { ...data, accessToken, user: undefined }
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        return { ...state, accessToken, user: undefined }
      }

      case actionTypes.Logout: {
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/jwt/logout/`).then((res) => {
        }).catch((e) => {
        })
        return data
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return { ...state, user }
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return { ...state, user }
      }

      case actionTypes.SetDataDashboard: {
        const payloadData = action.payload?.data
        const newData: DATA = {
          data: payloadData,
          expireTime: generateExpireTime()
        }
        const copy = structuredClone(state) 
        copy.data.dataDashboard = newData
        return copy
      }

      case actionTypes.SetDataScope: {
        const data = action.payload?.data
        const newData: DATA = {
          data: data,
          expireTime: generateExpireTime()
        }
         const copy = structuredClone(state) 
        copy.data.dataScope = newData
        return copy
      }

      case actionTypes.SetDataAsset: {
        const data = action.payload?.data
        const newData: DATA = {
          data: data,
          expireTime: generateExpireTime()
        }
         const copy = structuredClone(state) 
        copy.data.dataAsset = newData
        return copy
      }

      case actionTypes.SetDataVulnerability: {
        const data = action.payload?.data
        const newData: DATA = {
          data: data,
          expireTime: generateExpireTime()
        }
         const copy = structuredClone(state) 
        copy.data.dataVulnerability = newData
        return copy
      }

      case actionTypes.SetDataSSL: {
        const data = action.payload?.data
        const newData: DATA = {
          data: data,
          expireTime: generateExpireTime()
        }
         const copy = structuredClone(state) 
        copy.data.dataSSL = newData
        return copy
      }

      case actionTypes.SetDataDNS: {
        const data = action.payload?.data
        const newData: DATA = {
          data: data,
          expireTime: generateExpireTime()
        }
         const copy = structuredClone(state) 
        copy.data.dataDns = newData
        return copy
      }

      case actionTypes.SetDataSmtp: {
        const data = action.payload?.data
        const newData: DATA = {
          data: data,
          expireTime: generateExpireTime()
        }
         const copy = structuredClone(state) 
        copy.data.dataSmtp = newData
        return copy
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string) => ({ type: actionTypes.Login, payload: { accessToken } }),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: { accessToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user: UserModel) => ({ type: actionTypes.SetUser, payload: { user } }),
  setDataDashboard: (data: any) => ({ type: actionTypes.SetDataDashboard, payload: { data } }),
  setDataScope: (data: any) => ({ type: actionTypes.SetDataScope, payload: { data } }),
  setDataAsset: (data: any) => ({ type: actionTypes.SetDataAsset, payload: { data } }),
  setDataVulnerability: (data: any) => ({ type: actionTypes.SetDataVulnerability, payload: { data } }),
  setDataSSL: (data: any) => ({ type: actionTypes.SetDataSSL, payload: { data } }),
  setDataDns: (data: any) => ({ type: actionTypes.SetDataDNS, payload: { data } }),
  setDataSmtp: (data: any) => ({ type: actionTypes.SetDataSmtp, payload: { data } }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    // @ts-ignore
    // const getToken = (state) => state.auth.accessToken
    // // @ts-ignore
    // let token = yield select(getToken)
    // const {data: user} = yield getUserByToken(token)

    yield put(
      actions.fulfillUser({
        api_token: '$2y$10$lzYGs3CVjxdlR2ERLfZOyezaXM8qXLGd5fHEkjoBmDxznEl.CvAdC',
        created_at: '2022-07-14T11:37:39.000000Z',
        email: 'admin@demo.com',
        email_verified_at: '2022-07-14T11:37:39.000000Z',
        first_name: 'German',
        id: 2,
        last_name: 'Stark',
        updated_at: '2022-07-14T11:37:39.000000Z',
      })
    )
  })
}
