import { FC, Suspense , lazy} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
// import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
// import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {Dashboard1} from '../pages/dashboard1/Dashboard1'
import {Scope} from '../pages/scope'
import {Vulnerabilities} from '../pages/vulnerabilities'
import VulnerabilityDetails from '../pages/vulnerabilities/components/VulnerabilityDetails'
import {Asset} from '../pages/asset'
import AssetDetails from '../pages/asset/components/AssetDetails'
import {Waf} from '../pages/waf'
import {DNSInspect} from '../pages/dnsInspect'
import {SMTP} from '../pages/smtp'
import {SSL} from '../pages/ssl'
import {Schedule} from '../pages/schedule'
import {WorkFlow} from '../pages/workFlow'
import DAG from '../pages/workFlow/components/DAG'
// import {Report} from '../pages/report'
import SSLDetails from '../pages/ssl/components/SSLDetails'
import TechFilter from '../pages/scope/components/TechFilter'
import { Error404 } from '../modules/errors/components/Error404'
import { Scan } from '../pages/scan'
import ScanDetails from '../pages/scan/components/ScanDetails'
import CreateDAG from '../pages/workFlow/components/CreateDAG'

const PrivateRoutes = () => {
  // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='/dashboard' element={<Dashboard1 />} />
        <Route path='/scope' element={<Scope />} />
        <Route path='/scope-filter' element={<TechFilter />} />
        <Route path='/schedule' element={<Schedule />} />
        <Route path='/workFlow' element={<WorkFlow />} />
        <Route path='/workFlow-edit' element={<DAG />} />
        <Route path='/workFlow-create' element={<CreateDAG />} />
        {/* <Route path='/report' element={<Report />} /> */}
        <Route path='/vulnerabilities' element={<Vulnerabilities />} />
        <Route path='/vulnerability-details' element={<VulnerabilityDetails />} />
        <Route path='/assets' element={<Asset />} />
        <Route path='/scan' element={<Scan />} />
        <Route path='/scan-details' element={<ScanDetails />} />
        <Route path='/asset-details' element={<AssetDetails />} />
        <Route path='/waf' element={<Waf />} />
        <Route path='/dns' element={<DNSInspect />} />
        <Route path='/smtp' element={<SMTP />} />
        <Route path='/ssl' element={<SSL />} />
        <Route path='/ssl-details' element={<SSLDetails />} />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        {/* Lazy Modules */}
        {/* <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Error404 />} />
        {/* <Route path='*' element={<Navigate to='404' />} /> */}
      </Route>
    </Routes>
  )
}
type SuspensedViewProps = {
  children:React.ReactNode
}
const SuspensedView: FC<SuspensedViewProps> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
