import React, { useState } from 'react'
import '../../dnsInspect/components/style.scss'
const SubSection = ({data}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={`h-100 card bg-secondary`} >
      <div className={` p-2 w-100 d-flex  flex-column`}>
        <div
          className={`w-100 p-2 h-auto bg-gray-200 rounded-1 d-flex justify-content-between align-items-center  ${data?.status}`}
          onClick={() => {
             setIsOpen(!isOpen)
          }}
        >
          <h4 className='  m-0'>{data?.title}</h4>
          <img
            className='w-20px h-20px'
            src={`${data?.status === 'pass' ? '/media/pass.svg' : '/media/fail.svg'}`}
            alt='status'
          />
        </div>
        <div className={` px-2 w-100 d-flex  flex-column gap-4 overflow-hidden dns_items`} show={`${isOpen}`}>
          <div className='d-flex gap-3 w-100 align-items-center mt-4'>
            <strong className='w-80px'>Detail: </strong>
            <div dangerouslySetInnerHTML={{__html: data?.detail}} />
          </div>
          <div className='d-flex gap-3 w-100 align-items-center'>
            <strong className='w-85px'>Data: </strong>
            <div
              style={{padding: 10, borderRadius: 10}}
              className='w-100 card bg-body'
            >
              {data?.data && data?.data?.length ? (
                data?.data?.map((item) => <div key={item}>{item}</div>)
              ) : (
                <strong>No Data</strong>
              )}
            </div>
          </div>
          <div className='d-flex gap-3 w-100 align-items-center'>
            <strong className='w-80px'>Description:</strong>
            <div>{data?.description}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubSection
