import DatePicker from './components/DatePicker'

export const Schedule = () => {


  return (
    <div className='m-auto'>
      <DatePicker />
    </div>
  )
}
