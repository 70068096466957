import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as auth from '../app/modules/auth/redux/AuthRedux'
export default function useErrorHandling() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    function errorHandling(status: number | string) {
        console.log(status)
        if (status == 401) {
            dispatch(auth.actions.logout())
            navigate('/auth')
        }
        if (status == 404){
            navigate('/404')
        }
    }
    return errorHandling
}