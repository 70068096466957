import React from 'react'

const PageLoading = () => {
  return (
    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
      <span className='indicator-progress' style={{display: 'block'}}>
        Please wait...
        <span className='spinner-border spinner-lg spinner-border-lg align-middle ms-2'></span>
      </span>
    </div>
  )
}

export default PageLoading
