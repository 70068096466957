import React, {useEffect, useLayoutEffect, useState} from 'react'

import {ChartsWidget8 as ChartsWidget} from '../../../../_metronic/partials/widgets/charts/ChartsWidget8'
import sample from '../../sample'
import InfoIcon from '../../images/InfoIcon'
import ReactTooltip from 'react-tooltip'
const DefaultData = {
  x: ['start', '2023-06-17'],
  y: {
    critical: [0, 6],
    high: [0, 20],
    info: [0, 239],
    low: [0, 18],
    medium: [0, 180],
  },
}
const MainChart = ({title = '', data = DefaultData}) => {
  const [severityValue, setSeverityValue] = useState({
    info: true,
    low: true,
    medium: true,
    high: true,
    critical: true,
  })

  useLayoutEffect(() => {
    if (data?.x?.length < 2) {
      data?.x?.unshift('start')
    }
    if (data?.y?.info?.length < 2) {
      data?.y?.info?.unshift(0)
    }
    if (data?.y?.low?.length < 2) {
      data?.y?.low?.unshift(0)
    }
    if (data?.y?.high?.length < 2) {
      data?.y?.high?.unshift(0)
    }
    if (data?.y?.medium?.length < 2) {
      data?.y?.medium?.unshift(0)
    }
    if (data?.y?.critical?.length < 2) {
      data?.y?.critical?.unshift(0)
    }
  }, [data])

  const {
    tooltips: {vulnerabilitiesBasedOnSeverity},
  } = sample

  const severityBtnFunc = ({target}) => {
    let {value} = target

    if (!value) {
      value = target?.id
    }

    let severityValueTemp = {...severityValue}

    severityValueTemp[value] = !severityValueTemp[value]
    setSeverityValue(severityValueTemp)
  }
  return (
    <div className='col-6 h-400px'>
      <div className={`card card-body bg-body`}>
        <div className='d-flex flex-row align-items-center'>
          <h3 className='card-title align-items-start flex-column   pt-2'>{title}</h3>
          <p className='m-1' data-tip={vulnerabilitiesBasedOnSeverity}>
            <InfoIcon />
          </p>
          <ReactTooltip />
        </div>
        <ChartsWidget chartData={data} severityValue={severityValue} />
        <div className={`row d-flex flex-row`}>
          <button
            value='info'
            onClick={severityBtnFunc}
            style={{border: severityValue?.info && '2px solid'}}
            className={`w-auto h-auto  ms-2 me-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body  ${
              severityValue?.info ? 'border border-1 border-gray-900 ' : 'border border-1'
            }`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-iinfo border border-2' />
            info
          </button>
          <button
            value='low'
            onClick={severityBtnFunc}
            style={{border: severityValue?.low && '2px solid '}}
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body ${
              severityValue?.low ? 'border border-1 border-gray-900' : 'border border-1'
            }`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-ilow border border-2' />
            low
          </button>
          <button
            value='medium'
            onClick={severityBtnFunc}
            style={{border: severityValue?.medium && '2px solid '}}
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body ${
              severityValue.medium ? 'border border-1 border-gray-900' : 'border border-1'
            }`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-imedium border border-2' />
            medium
          </button>
          <button
            value='high'
            onClick={severityBtnFunc}
            style={{border: severityValue?.high && '2px solid '}}
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body ${
              severityValue.high ? 'border border-1 border-gray-900' : 'border border-1'
            }`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-ihigh border border-2' />
            high
          </button>
          <button
            value='critical'
            onClick={severityBtnFunc}
            style={{border: severityValue?.critical && '2px solid '}}
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body ${
              severityValue?.critical ? 'border border-1 border-gray-900' : 'border border-1'
            }`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-icritical border border-2' />
            critical
          </button>
        </div>
      </div>
    </div>
  )
}

export default MainChart
