import React, {useState, useEffect} from 'react'
import axios from 'axios'

import NewTechnologies from './components/NewTechnologies'
import PageLoading from '../loading/PageLoading'
import ReactPaginate from 'react-paginate'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {actions} from '../../modules/auth'
export const Asset = () => {
  const [loading, setLoading] = useState(false)
  const assetData = useSelector((state) => state?.auth?.data?.dataAsset)
  const dispatch = useDispatch()

  const errorHandling = useErrorHandling()
  const fetchAssetData = async (pageCount=1) => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/assets/?page=${pageCount}`)
      errorHandling(data.status)//logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(actions.setDataAsset(data.data))
      } else {
        console.error(`error in get data from ${process.env.REACT_APP_API_ENDPOINT}/assets/?page=${pageCount} \n`, data)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (assetData?.expireTime && assetData.expireTime >= nowDate) {
    } else {
      fetchAssetData()
    }
  }, [])
  const handlePageClick = (event) => {
    fetchAssetData(event.selected+1)
   }
  return (
    <>
      {loading ? (
        <PageLoading />
      ) : ( assetData?.data ?
        <>
        <div className='row d-flex flex-row  mt-5 overflow-auto'>
          <NewTechnologies assetData={assetData?.data?.results} />
        </div>
        <div className='d-flex justify-content-end bg-body pb-4 pe-4 rounded-bottom'>
            <ReactPaginate
              nextLabel='next >'
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={assetData?.data?.total_pages}
              forcePage={assetData?.data?.current_page-1}
              previousLabel='< previous'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
          </div>
        </>:null
      )}
    </>
  )
}
