import Chart from 'react-apexcharts'

const ColumnChart = ({
  height = 80,
  colors = ['#96bded'],
  data = [10, 20, 30, 15, 18, 5],
  categories = ['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul'],
}) => {
  const series = [
    {
      name: 'Number',
      data: data,
    },
  ]

  const options = {
    colors: colors,
    chart: {
      height: '100%',
      type: 'bar',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '4px',
        borderRadius: 2,
      },
    },
    markers: {
      size: 2,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    // title: {
    //   text: 'Product Trends by Month',
    //   align: 'left',
    // },
    tooltip: {
      theme: 'dark',
      style: {
        fontSize: '12px',
      },
    },
    grid: {
      show: false,
    },
    xaxis: {
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      categories:categories,
    },
    yaxis: {
      show: false,
    },
  }

  return <Chart width={120} height={height} options={options} series={series} type='bar' />
}

export default ColumnChart
