/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import PageLoading from '../../loading/PageLoading'
import axios from 'axios'
import LastStatus from 'react-last-status'
import useErrorHandling from '../../../../Utils/useErrorHandling'
const severityContent = (severity) => {
  switch (severity) {
    case 'info':
      return (
        <>
          <div className={`w-15px h-15px rounded-3 border border-2 border-iinfo me-3`} />
          <span className='m-auto'>Info Severity </span>
        </>
      )
    case 'low':
      return (
        <>
          <div className={`w-15px h-15px rounded-3 border border-2 border-ilow me-3`} />
          <span className='m-auto'>Low Severity </span>
        </>
      )

    case 'medium':
      return (
        <>
          <div className={`w-15px h-15px rounded-3 border border-2 border-imedium me-1`} />
          <span className='m-auto'>Medium Severity </span>
        </>
      )

    case 'high':
      return (
        <>
          <div className={`w-15px h-15px rounded-3 border border-2 border-ihigh me-3`} />
          <span className='m-auto'>High Severity </span>
        </>
      )

    case 'critical':
      return (
        <>
          <div className={`w-15px h-15px rounded-3 border border-2 border-icritical me-3`} />
          <span className='m-auto'>Critical Severity </span>
        </>
      )

    default:
      return (
        <>
          <div className={`w-15px h-15px rounded-3 border border-2 border-white me-3`} />
          <span className='m-auto'>None</span>
        </>
      )
  }
}

export const DetailsDrawer = ({id, setId}) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [commentValue, setCommentValue] = useState('')
  const errorHandling = useErrorHandling()
  const fetchVulnerabilityDetailData = async () => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/${id}/`
      )
      errorHandling(data.status)//logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        setData(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/${id} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useEffect(() => {
    if (id !== null) fetchVulnerabilityDetailData()
  }, [id])
function sendComment(){
}
  return data?(
    <>
      {/* begin::Demos drawer */}
      <div
        id='kt_engage_filters'
        className={`bg-body drawer drawer-end ${id !== null ? 'drawer-on' : ''}`}
        data-kt-drawer-width="{default:'350px', 'lg': '375px'}"
        style={{minWidth: 400, maxWidth: 400}}
      >
        <>
          {/* begin::Card  */}
          <div className='card shadow-none w-100'>
            {/* begin::Header */}
            <div className='card-header' id='kt_explore_header'>
              <h5 className='card-title fw-bold text-gray-600'>{!loading && data?.cve}</h5>

              <div className='card-toolbar'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                  id='kt_engage_filters_close'
                  onClick={() => {
                    setId(null)
                  }}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2 image_change_theme2' />
                </button>
              </div>
            </div>
            {/* end::Header */}
            {loading ? (
              <PageLoading />
            ) : (
              <>
                {/* begin::Body */}
                <div className='card-body d-flex flex-column gap-7' id='kt_explore_body'>
                  {/* begin::Content */}

                  <div className='d-flex flex-column justify-content-start'>
                    <div className='p-1 text-gray-700 h-auto'>AFFECTED ENTITY </div>
                    <div className='w-200px p-1 mt-2 d-flex align-items-center text-primary h-auto'>
                      {data?.host}
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-start'>
                    <div className='w-200px p-1 text-gray-700 h-auto'>Targets </div>
                    <div className='w-200px p-1 mt-1 d-flex align-items-center text-gray-800 h-auto'>
                      <div
                        className={`w-30px h-30px bg-gray-500 text-white rounded-1 d-flex justify-content-center align-items-center p-1 me-3`}
                      >
                        {data?.tag}
                      </div>
                      {data?.target}
                    </div>
                  </div>

                  <div>
                    <span className='min-w-200px p-1 text-gray-700 h-auto'>Risk</span>
                    <div className='px-3 py-1 d-flex mw-200px my-2 align-items-center bg-secondary rounded-3'>
                      {severityContent(data?.severity)}
                    </div>
                  </div>

                  <div className='d-flex flex-column justify-content-start'>
                    <div className='min-w-200px p-1 text-gray-700 h-auto'>
                      AFFECTED TECHNOLOGIES
                    </div>
                    <div className='p-1 mt-2 text-gray-900'>{data?.component}</div>
                  </div>
                  <div className='d-flex flex-column justify-content-start'>
                    <div className='w-150px p-1 text-gray-700 h-auto'> LAST SEEN </div>
                    <span className='w-150px p-1 mt-2 text-gray-900 h-auto'>
                      <LastStatus
                        timestamp={data?.timestamp}
                        // now={'[I am alive]'}
                        // sameDay={'[last seen today at] HH:mm'}
                        // lastDay={'[last seen yesterday at] HH:mm'}
                        // lastWeek={'[last seen] dddd [at] HH:mm'}
                        // sameElse={'[last seen] L [at] HH:mm'}
                      />
                    </span>
                  </div>
                  <div>
                    <div className='min-w-200px p-1 text-gray-700 h-auto'>Description</div>
                    <div className='my-2'>{data?.detail}</div>
                  </div>
                  <div className=' d-flex flex-column '>
                    <label for='' className='form-label'>
                      Comment
                    </label>
                    <textarea
                      onChange={(e) => {
                        setCommentValue(e.target.value)
                      }}
                      value={commentValue}
                      rows={1}
                      className='form-control form-control-solid'
                      data-kt-autosize='true'
                      placeholder='Comment Text'
                    ></textarea>
                  </div>
                  <button className='btn btn-primary btn-sm' onClick={sendComment}>Send Comment</button>
                  {/* end::Content */}
                </div>
                {/* end::Body */}
              </>
            )}
          </div>
          {/* begin::Card */}
        </>
      </div>
      {/* end::Demos drawer */}
    </>
  ):null
}
