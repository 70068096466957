import React, { useEffect } from 'react'
import ColumnChart from './ColumnChart'
import InfoIcon from '../../images/InfoIcon'

const Card = ({
  icon = <i className='bi bi-bookmarks-fill fs-2x' />,
  header = '55',
  subHeader = 'SAP UI Progress',
  bgColor = 'bg-dark',
  subColor = ' ',
  chartColors,
  data = [10,20,30,10],
  categories
}) => {
  return (
    <div className='d-flex flex-column h-150px col-3'>
      <div className={`h-100px card card-body ${bgColor}`}>
        <div className='row d-flex flex-row'>
          <div className='col-6'>
            {icon}
            <div className={`${subColor} fw-bolder fs-1 mt-6 mb-8`}>{header}</div>
          </div>

          <div className='col-6 h-100 mt-1'>
            <ColumnChart categories={categories} height={80} data={data} colors={chartColors} />
          </div>
        </div>
        <div className='d-flex fw-bold text-igray'>
          {subHeader}
          <p className='ms-2' data-tip={'test'}>
            <InfoIcon />
          </p>
          </div>
      </div>
    </div>
  )
}

export default Card
