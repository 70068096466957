import React, {useState, useEffect} from 'react'
import LastStatus from 'react-last-status'
import {useNavigate, useSearchParams} from 'react-router-dom'
import axios from 'axios'

import PageLoading from '../../loading/PageLoading'
import Arr001 from '../../components/Arr001'

import ChartLine from './ChartLine'
import ImageModal from './ImageModal'
import HistoryModal from './HistoryModal'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import Timeline from './Timeline'

const data = [
  {
    date: '2024-10-11',
    percentage: null,
  },
  {
    date: '2024-10-10',
    percentage: null,
  },
  {
    date: '2024-10-9',
    percentage: null,
  },
  {
    date: '2024-10-8',
    percentage: null,
  },
  {
    date: '2024-10-7',
    percentage: null,
  },
  {
    date: '2024-10-6',
    percentage: null,
  },
  {
    date: '2024-10-5',
    percentage: null,
  },
  {
    date: '2024-10-4',
    percentage: null,
  },
  {
    date: '2024-10-3',
    percentage: null,
  },
  {
    date: '2024-10-2',
    percentage: null,
  },
  {
    date: '2024-10-1',
    percentage: null,
  },
  {
    date: '2023-10-12',
    percentage: null,
  },
  {
    date: '2023-10-11',
    percentage: null,
  },
  {
    date: '2023-10-10',
    percentage: null,
  },
  {
    date: '2023-10-9',
    percentage: null,
  },
  {
    date: '2023-10-8',
    percentage: null,
  },
  {
    date: '2023-10-10',
    percentage: null,
  },
  {
    date: '2023-10-9',
    percentage: null,
  },
  {
    date: '2023-10-8',
    percentage: null,
  },
  {
    date: '2023-10-10',
    percentage: null,
  },
  {
    date: '2023-10-9',
    percentage: null,
  },
  {
    date: '2023-10-8',
    percentage: null,
  },
  {
    date: '2023-10-10',
    percentage: null,
  },
  {
    date: '2023-10-9',
    percentage: null,
  },
  {
    date: '2023-10-8',
    percentage: null,
  },
  {
    date: '2024-10-12',
    percentage: 100,
  },
]
const certificatesData = [
  {
    name: 'netspi.com',
    details: ['TLS-1', 'TLS-2', 'TLS-3', 'TLS-4', 'TLS-5'],
  },
  {
    name: 'netspi2.com',
    details: ['TLS-12', 'TLS-22', 'TLS-32', 'TLS-42', 'TLS-52'],
  },
  {
    name: 'netspi3.com',
    details: ['TLS-13', 'TLS-23', 'TLS-33', 'TLS-43', 'TLS-53'],
  },
]

const AssetDetails = () => {
  const [loading, setLoading] = useState(true)
  const [certificateDataId, setCertificateDataId] = useState(null)
  const [assetDetailData, setAssetDetailData] = useState()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const errorHandling = useErrorHandling()
  useEffect(() => {
    setLoading(true)
    const fetchAssetDetailData = async () => {
      try {
        const data = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/assets/${searchParams.get('id')}/`
        )
        errorHandling(data.status) //logout when invalid token && redirect 404
        if (data.status >= 200 && data.status < 300) {
          setAssetDetailData(data.data)
        } else {
          console.error(
            `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/assets/${searchParams.get(
              'id'
            )} \n`,
            data
          )
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
    fetchAssetDetailData()
  }, [])

  return (
    <>
      {loading ? (
        <PageLoading />
      ) : assetDetailData ? (
        <>
          <div className='card card-body'>
            <div className='d-flex justify-content-between  align-items-center'>
              <div className='row d-flex col-8 gx-0 p-3'>
                <div className='d-flex h-auto justify-content-between align-items-start pt-2'>
                  <h3 className=' '>{assetDetailData?.host}</h3>
                </div>
                <div className='w-100 p-2 d-flex align-items-center justify-content-between'>
                  <div className='w-200px p-1 d-flex align-items-center  h-auto'>
                    <div
                      className={`w-30px h-30px bg-gray-600  rounded-1 d-flex justify-content-center align-items-center p-1 me-3`}
                    >
                      {assetDetailData?.tag}
                    </div>
                    {assetDetailData?.targets}
                  </div>
                  <div className='d-flex flex-row align-items-center'>
                    <span className=' fs-6'>last seen:</span>
                    <span className='text-gray-800 fs-7 ms-3'>
                      <LastStatus timestamp={assetDetailData?.timestamp} />
                    </span>
                  </div>
                </div>
              </div>
              <button
                className='btn btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#historyModal'
                style={{height: 'fit-content'}}
              >
                Scan History
              </button>
            </div>
            <div className={` w-100  d-flex flex-row h-100 bg-body `}>
              <div className='d-flex w-100 h-100 gap-5'>
                <div className='d-flex w-50 h-100 gap-5 flex-column'>
                  <div className='card p-5 bg-mh_black'>
                    <h2>
                      <strong>DNS Records</strong>
                    </h2>
                    <div className='mt-5'>
                      <h3>A Records</h3>
                      <div className='d-flex flex-wrap gap-2 border-bottom pb-5 '>
                        {assetDetailData?.dns?.a
                          ? assetDetailData?.dns?.a?.map((item, indx) => (
                              <div key={indx} className='p-1 rounded bg-secondary bg-gradient'>
                                {item}
                              </div>
                            ))
                          : 'No Data'}
                      </div>
                    </div>
                    <div className='mt-5'>
                      <h3>MX Records</h3>
                      <div className='d-flex flex-wrap gap-2 border-bottom pb-5 '>
                        {assetDetailData?.dns?.mx
                          ? assetDetailData?.dns?.mx?.map((item, indx) => (
                              <div key={indx} className='p-1 rounded bg-secondary bg-gradient'>
                                {item}
                              </div>
                            ))
                          : 'No Data'}
                      </div>
                    </div>
                    <div className='mt-5'>
                      <h3>NS Records</h3>
                      <div className='d-flex flex-wrap gap-2  pb-5 '>
                        {assetDetailData?.dns?.ns
                          ? assetDetailData?.dns?.ns?.map((item, indx) => (
                              <div key={indx} className='p-1 rounded bg-secondary bg-gradient'>
                                {item}
                              </div>
                            ))
                          : 'No Data'}
                      </div>
                    </div>
                  </div>

                  <div className='card p-5 bg-mh_black'>
                    <h2>
                      <strong>Locations</strong>
                    </h2>
                    <div className='d-flex flex-wrap gap-2 mt-2'>
                      {assetDetailData?.ipv4?.length
                        ? assetDetailData?.ipv4?.map((item, indx) => (
                            <div key={indx} className='p-1 rounded bg-secondary bg-gradient'>
                              {item.loc}
                            </div>
                          ))
                        : 'No Location'}
                    </div>
                  </div>
                  <div className='card p-5 bg-mh_black'>
                    <h2>
                      <strong>ASNS</strong>
                    </h2>
                    <div className='d-flex flex-wrap gap-2 mt-2'>
                      {assetDetailData?.ipv4?.length
                        ? assetDetailData?.ipv4?.map((item, indx) => (
                            <div key={indx} className='p-1 rounded bg-secondary bg-gradient'>
                              {item.asn}
                            </div>
                          ))
                        : 'No ASNS'}
                    </div>
                  </div>
                  <div className='card p-5 bg-mh_black'>
                    <h2>
                      <strong>CIDRS</strong>
                    </h2>
                    <div className='d-flex flex-wrap gap-2 mt-2'>
                      {assetDetailData?.ipv4?.length
                        ? assetDetailData?.ipv4?.map((item, indx) => (
                            <div key={indx} className='p-1 rounded bg-secondary bg-gradient'>
                              {item.cidr}
                            </div>
                          ))
                        : 'No CIDRS'}
                    </div>
                  </div>
                  <div className='card p-5 bg-mh_black'>
                    <h2>
                      <strong>IP Address</strong>
                    </h2>
                    <h4 className='mt-5'>V4</h4>
                    <div className='d-flex flex-column gap-5 mt-2'>
                      {assetDetailData?.ipv4?.length
                        ? assetDetailData?.ipv4?.map((item, indx) => (
                            <div key={indx} className='p-1 rounded bg-secondary bg-gradient  '>
                              <div className='d-flex flex-wrap gap-5'>
                                <div className='d-flex gap-2'>
                                  <strong>asn</strong> : <p>{item.asn || 'No Data'}</p>
                                </div>
                                <div className='d-flex gap-2'>
                                  <strong>cidr</strong> : <p>{item.cidr || 'No Data'}</p>
                                </div>
                              </div>
                              <div className='d-flex flex-wrap gap-5'>
                                <div className='d-flex gap-2'>
                                  <strong>ipv4</strong> : <p>{item.ipv4 || 'No Data'}</p>
                                </div>
                                <div className='d-flex gap-2'>
                                  <strong>loc</strong> : <p>{item.loc || 'No Data'}</p>
                                </div>
                              </div>
                              {/* <div className='d-flex'>
                            <strong>latitude</strong> : <p>{item.latitude || 'No Data'}</p>
                          </div>
                          <div className='d-flex'>
                            <strong>longitude</strong> : <p>{item.longitude || 'No Data'}</p>
                          </div> */}
                            </div>
                          ))
                        : 'No IP'}
                    </div>
                    <h4 className='mt-5'>V6</h4>
                    <div className='d-flex flex-wrap gap-5 mt-2'>
                      {assetDetailData?.ipv6?.length
                        ? assetDetailData?.ipv6?.map((item, indx) => (
                            <div key={indx} className='p-1 rounded bg-secondary bg-gradient  '>
                              <div className='d-flex flex-wrap gap-5'>
                                <div className='d-flex gap-2'>
                                  <strong>asn</strong> : <p>{item.asn || 'No Data'}</p>
                                </div>
                                <div className='d-flex gap-2'>
                                  <strong>cidr</strong> : <p>{item.cidr || 'No Data'}</p>
                                </div>
                              </div>
                              <div className='d-flex flex-wrap gap-5'>
                                <div className='d-flex gap-2'>
                                  <strong>ipv6</strong> : <p>{item.ipv6 || 'No Data'}</p>
                                </div>
                                <div className='d-flex gap-2'>
                                  <strong>loc</strong> : <p>{item.loc || 'No Data'}</p>
                                </div>
                              </div>
                              {/* <div className='d-flex'>
                            <strong>latitude</strong> : <p>{item.latitude || 'No Data'}</p>
                          </div>
                          <div className='d-flex'>
                            <strong>longitude</strong> : <p>{item.longitude || 'No Data'}</p>
                          </div> */}
                            </div>
                          ))
                        : 'No IP'}
                    </div>
                  </div>
                </div>

                {/* mid */}
                <div className='d-flex w-50 h-100 gap-5 flex-column'>
                  <div className='card p-5 bg-mh_black'>
                    <h2>
                      <strong>Live Timeline</strong>
                    </h2>
                    <Timeline data={data} />
                  </div>
                  <div className='card p-5 bg-mh_black'>
                    <div className='d-flex justify-content-between'>
                      <h2>
                        <strong>Vulnerabilities History</strong>
                      </h2>
                      30 day
                    </div>
                    <div className='d-flex justify-content-between  align-items-center gap-3'>
                      <div>
                        <h2>
                          <strong>{assetDetailData?.total_vulnerabilities || 123}</strong>
                        </h2>
                        <span style={{fontSize: 10}}>Vulnerabilities</span>
                      </div>
                      <ChartLine />
                    </div>
                  </div>
                  <div className='card p-5 bg-mh_black'>
                    <h2>
                      <strong>Technologies</strong>
                    </h2>
                    <div className='d-flex flex-wrap gap-2 mt-5'>
                      {assetDetailData?.techs?.map((item, indx) => (
                        <div key={indx} className='p-1 rounded bg-secondary bg-gradient'>
                          {item}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='card p-5 bg-mh_black'>
                    <h2>
                      <strong>Ports</strong>
                    </h2>
                    <div className='d-flex flex-wrap gap-2 mt-5'>
                      {assetDetailData?.ports?.map((item, indx) => (
                        <div key={indx} className='p-1 rounded bg-secondary bg-gradient'>
                          {item.port}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className='card p-5 bg-mh_black'>
                    <div className='d-flex justify-content-between'>
                      <div>
                        <h2>
                          <strong>Certificate</strong>
                        </h2>
                        <p>w/ Protocols and Ciphers</p>
                      </div>
                      <button
                        style={{height: 'fit-content'}}
                        className='btn btn-primary btn-sm'
                        onClick={() => {
                          if (certificateDataId === null) {
                            setCertificateDataId(-1)
                          } else setCertificateDataId(null)
                        }}
                      >
                        {certificateDataId === null ? 'Show' : 'Hide'} details
                      </button>
                    </div>
                    <div className='d-flex flex-wrap gap-2 mt-2'>
                      {certificatesData.length &&
                        certificatesData?.map((items, ind) => {
                          return (
                            <div
                              className='certificates'
                              show={`${!!(certificateDataId === ind || certificateDataId === -1)}`}
                              key={ind}
                            >
                              <div
                                className='p-1 w-fit rounded bg-secondary bg-gradient pe-auto'
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                  if (certificateDataId === ind) setCertificateDataId(null)
                                  else setCertificateDataId(ind)
                                }}
                              >
                                {items.name}
                              </div>
                              <div className='certificates_details'>
                                {items.details?.map((item, index) => (
                                  <p key={index}>{item}</p>
                                ))}
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
                <div className={`d-flex w-50 h-100 gap-5 flex-column  `}>
                  <div
                    className=' d-flex flex-row p-1 pe-3 h-50 card p-5 '
                    style={{maxHeight: 600}}
                  >
                    <div className='d-flex flex-column bg-mh_black h-100 w-100'>
                      <div className='w-100 h-40px fs-4  pt-3 border-bottom border-2 border-gray-500'>
                        Associated Vulnerabilities
                      </div>

                      <div className='w-100 d-flex flex-row h-40px pt-3 pb-3'>
                        <div className='px-3 py-3 d-flex flex-row align-items-center bg-secondary rounded-3'>
                          <span>{`${assetDetailData?.total_vulnerabilities}`}</span>
                        </div>
                        <div className={`w-2px h-100 bg-white mx-3`} />
                        <div className='px-2 py-3 d-flex flex-row align-items-center bg-secondary rounded-3'>
                          <div className={`w-15px h-15px rounded-3 bg-icritical me-2`} />
                          <span>{assetDetailData?.vulnerabilities?.critical}</span>
                        </div>
                        <div className='px-2 py-3 ms-2 d-flex flex-row align-items-center bg-secondary rounded-3'>
                          <div className={`w-15px h-15px rounded-3 bg-ihigh me-2`} />
                          <span>{assetDetailData?.vulnerabilities?.high}</span>
                        </div>
                        <div className='px-2 py-3 ms-2 d-flex flex-row align-items-center bg-secondary rounded-3'>
                          <div className={`w-15px h-15px rounded-3 bg-imedium me-2`} />
                          <span>{assetDetailData?.vulnerabilities?.medium}</span>
                        </div>
                        <div className='px-2 py-3 ms-2 d-flex flex-row align-items-center bg-secondary rounded-3'>
                          <div className={`w-15px h-15px rounded-3 bg-ilow me-2`} />
                          <span>{assetDetailData?.vulnerabilities?.low}</span>
                        </div>
                        <div className='px-2 py-3 ms-2 d-flex flex-row align-items-center bg-secondary rounded-3'>
                          <div className={`w-15px h-15px rounded-3 bg-iinfo me-2`} />
                          <span>{assetDetailData?.vulnerabilities?.info}</span>
                        </div>
                      </div>

                      <div className='d-flex flex-column overflow-auto mt-1 p-2 pt-3 pb-3'>
                        {assetDetailData?.vulnerabilities_list?.map((vulnerability, index, arr) => {
                          return (
                            <div
                              key={`assets detail list - ${vulnerability?.id}`}
                              className={`d-flex flex-column w-100 bg-secondary p-3 border border-box m-0 rounded-3 ${
                                index === arr.length - 1 ? null : 'mb-3'
                              }`}
                            >
                              <div className='w-100 d-flex flex-row align-items-center justify-content-between h-30px text-capitalize'>
                                <div className='w-100 d-flex flex-row'>
                                  <div
                                    className={`w-15px h-15px rounded-3 border border-2 me-2 border-${
                                      vulnerability?.severity && `i${vulnerability?.severity}`
                                    }`}
                                  />
                                  {vulnerability?.severity}
                                  <div className='w-1px min-h-20px gx-0 bg-dark me-3 ms-3' />
                                  <div className={`w-auto p-0 m-0 text-uppercase text-gray-700`}>
                                    <LastStatus timestamp={vulnerability?.timestamp} />
                                  </div>
                                </div>

                                <button
                                  className={`col-auto border border-0  bg-secondary p-0 m-0`}
                                  onClick={() => {
                                    navigate({
                                      pathname: '/vulnerability-details',
                                      search: `?id=${vulnerability.id}`,
                                    })
                                  }}
                                >
                                  <Arr001 fill={'#fff'} className={'image_change_theme'} />
                                </button>
                              </div>
                              <div className={`w-100 h-auto d-flex align-items-center p-0`}>
                                {vulnerability.title}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>

                  <div className=' d-flex flex-row p-1 pe-3 h-50 card p-5'>
                    <div className='d-flex flex-column h-100 bg-mh_black w-100'>
                      <div className='w-100 h-40px fs-4  pt-3 border-bottom border-2 border-gray-500'>
                        ScreenShot
                      </div>
                      <div
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target='#exampleModal'
                        className='w-100 h-100 p-3 d-flex justify-content-center align-items-center pt-3 cursor-pointer'
                        style={{
                          background: `url(${assetDetailData.screen_shot})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ImageModal url={assetDetailData?.screen_shot} />
          <HistoryModal data={[{date: '2024/12/12', change: 22}]} />
        </>
      ) : null}
    </>
  )
}

export default AssetDetails
