import {useIntl} from 'react-intl'
// import {KTIcon} from '../../../../helpers'
// import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <div className='d-flex flex-column justify-content-between h-100'>
      <div>
        <SidebarMenuItem
          icon='color-swatch'
          title={intl.formatMessage({id: 'Dashboard'})}
          to='/dashboard'
        />
        <SidebarMenuItem
          icon='fingerprint-scanning'
          title={intl.formatMessage({id: 'Scan'})}
          to='/scan'
        />
        <SidebarMenuItem icon='switch' title={intl.formatMessage({id: 'Targets'})} to='/scope' />
        <SidebarMenuItem icon='educare' title={intl.formatMessage({id: 'Assets'})} to='/assets' />
        <SidebarMenuItem
          icon='cross-circle'
          title={intl.formatMessage({id: 'Vulnerabilities'})}
          to='/vulnerabilities'
        />

        <SidebarMenuItem icon='lock-3' title={intl.formatMessage({id: 'SSL Check'})} to='/ssl' />
        <SidebarMenuItem
          icon='magnifier'
          title={intl.formatMessage({id: 'DNS Inspectbilities'})}
          to='/dns'
        />
        <SidebarMenuItem
          icon='sms'
          title={intl.formatMessage({id: 'Mail Security Scanner'})}
          to='/smtp'
        />
        <SidebarMenuItem
          icon='shield-cross'
          title={intl.formatMessage({id: 'WAF Security Assessment'})}
          to='/waf'
        />
        <SidebarMenuItem icon='rocket' title={intl.formatMessage({id: 'DoS Testing'})} to='/dos' />
        <SidebarMenuItem
          icon='calendar-8'
          title={intl.formatMessage({id: 'Schedule'})}
          to='/schedule'
        />
        <SidebarMenuItem icon='data' title={intl.formatMessage({id: 'Workflow'})} to='/workFlow' />
      </div>
    </div>
  )
}

export {SidebarMenuMain}
