import React, {useState, useEffect} from 'react'
import axios from 'axios'
import ReactPaginate from 'react-paginate'

import NewTechnologies from './components/NewTechnologies'
import PageLoading from '../loading/PageLoading'

import {DetailsDrawer} from './components/DetailsDrawer'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {actions} from '../../modules/auth'
export const Vulnerabilities = () => {
  const [loading, setLoading] = useState(false)
  const errorHandling = useErrorHandling()
  const [id, setId] = useState(null)
  const vulnerabilityData = useSelector((state) => state?.auth?.data?.dataVulnerability)
  const dispatch = useDispatch()
  const handlePageClick = (event) => {
    fetchVulnerabilityData(event.selected+1)
  }

  const fetchVulnerabilityData = async (page=1) => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/?page=${page}`)
      errorHandling(data.status)//logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(actions.setDataVulnerability(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/?page=${page} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (vulnerabilityData?.expireTime && vulnerabilityData.expireTime >= nowDate) {
    } else {
      fetchVulnerabilityData()
    }
  }, [])


  return (
    <>
      <DetailsDrawer id={id} setId={setId} />
      {loading ? (
        <PageLoading />
      ) : (vulnerabilityData?.data ?
        <>
          <div className='row d-flex flex-row  mt-5'>
            <NewTechnologies
              setId={setId}
              vulnerabilityData={vulnerabilityData?.data?.results}
            />
          </div>
          <div className='d-flex justify-content-end bg-body pb-4 pe-4'>
          <ReactPaginate
              nextLabel='next >'
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={vulnerabilityData?.data?.total_pages}
              forcePage={vulnerabilityData?.data?.current_page-1}
              previousLabel='< previous'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
          </div>
        </> : null
      )}
    </>
  )
}
