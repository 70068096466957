// import React from 'react'
// import InfoIcon from '../../images/InfoIcon'

// const Assets = ({className, data}) => {
//   console.log(data)
//   return (
//     // <div className='w-100 h100'>
//     <div className={`bg-body h-100 card mh-250px`}>
//       {/* start title */}
//       <div
//         className={`w-100 h-50px p-4 fw-bolder d-flex justify-content-center align-items-center text-body bg-secondary rounded-top`}
//       >
//         {data?.header}
//         <p className='mt-3 ms-2' data-tip={'test'}>
//           <InfoIcon />
//         </p>
//       </div>
//       <div
//         className={`w-100 h-100 p-0 pt-4 d-flex flex-column justify-content-center align-items-center ${className}`}
//       >
//         <span
//           style={{
//             fontSize: '3em',
//           }}
//           className='pt-2 d-flex align-items-center fw-bolder text-igray'
//         >
//           {data?.count}
//         </span>
//         <span className='d-flex align-items-center fw-bolder text-gray-700'>{data?.title}</span>
//         <span className='d-flex w-100 align-items-center fs-5 justify-content-center fw-bolder text-gray-700 mt-2'>
//           {data?.desc}
//         </span>
//       </div>
//     </div>
//     // </div>
//   )
// }

// export default Assets

import React from 'react'
import InfoIcon from '../../images/InfoIcon'

const Assets = ({className, data}) => {
  return (
    // <div className='w-100 h100'>
    <div className={`bg-body h-100 card mh-250px`}>
      {/* start title */}
      <div
        className={`w-100 h-50px p-4 fw-bolder d-flex justify-content-center align-items-center text-body bg-secondary rounded-top`}
      >
        {data?.header}
        <p className='mt-3 ms-2' data-tip={'test'}>
          <InfoIcon />
        </p>
      </div>
      <div
        className={`w-100 h-100 p-0 pt-4 d-flex flex-column justify-content-center align-items-center ${className}`}
      >
        <span
          style={{
            fontSize: '3em',
          }}
          className='pt-2 d-flex align-items-center fw-bolder text-igray'
        >
          {data?.count}
        </span>
        <span className='d-flex align-items-center fw-bolder text-gray-700'>{data?.title}</span>
        <span className='d-flex w-100 align-items-center fs-5 justify-content-center fw-bolder text-gray-700 mt-2'>
          {data?.desc}
        </span>
      </div>
    </div>
    // </div>
  )
}

export default Assets
