import React, {memo, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {nodes} from '../sample'

const DrawerDags = memo(({dragHandler}: {dragHandler: (item: any) => void}) => {
  const [show, setShow] = useState(true)
  const [type, setType] = useState('')
  return (
    <>
      {/* {start drawer} */}
      <div
        role='dialog'
        className='work_drawer bg-secondary card'
        //@ts-ignore
        show={`${show}`}
      >
        <div className='node_item'>
          <div
            className='btn-group btn-group-sm'
            role='group'
            aria-label='Basic radio toggle button group'
          >
            <input
              type='radio'
              className='btn-check'
              name='btnradio'
              id='btnradio1'
              autoComplete='off'
              checked={type === 'output'}
            />
            <label
              className='btn btn-primary bg-body'
              htmlFor='btnradio1'
              onClick={() => {
                setType('output')
              }}
            >
              Source
            </label>

            <input
              type='radio'
              className='btn-check'
              name='btnradio'
              id='btnradio2'
              autoComplete='off'
              checked={type === ''}
            />
            <label
              className='btn btn-primary bg-body'
              htmlFor='btnradio2'
              onClick={() => {
                setType('')
              }}
            >
              Connector
            </label>

            <input
              type='radio'
              className='btn-check'
              name='btnradio'
              id='btnradio3'
              autoComplete='off'
              checked={type === 'input'}
            />
            <label
              className='btn btn-primary bg-body'
              htmlFor='btnradio3'
              onClick={() => {
                setType('input')
              }}
            >
              Target
            </label>
          </div>
          <div className='input-icon'>
            <input type='text' className='form-control' placeholder='Search...' />
          </div>
          <div className='accordion' id='kt_accordion_1'>
            {nodes?.map((items, ind) => (
              <div className='accordion-item' key={ind + 1}>
                <h2 className='accordion-header' id={`kt_accordion_1_header_${ind + 1}`}>
                  <button
                    className={`accordion-button fs-4 fw-semibold collapsed`}
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target={`#kt_accordion_1_body_${ind + 1}`}
                    aria-expanded='true'
                    aria-controls={`kt_accordion_1_body_${ind + 1}`}
                  >
                    {items.category}
                  </button>
                </h2>
                <div
                  id={`kt_accordion_1_body_${ind + 1}`}
                  className='accordion-collapse collapse'
                  aria-labelledby={`kt_accordion_1_header_${ind + 1}`}
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    {items.nodes?.map((item, index) => (
                      <div
                        key={index}
                        draggable={true}
                        className='workflow_node_item mt-2'
                        onDragStart={() => {
                          dragHandler({
                            ...item,
                            className: 'large_item',
                            type: type,
                          })
                        }}
                      >
                        <div style={{width: 40, height: 40}} className={'large_item'}></div>
                        <p>{item.label}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button
          className='work_drawer_button bg-secondary'
          style={{border: 'none'}}
          onClick={() => {
            setShow(!show)
          }}
        >
          <div className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle svg_provider '>
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180 image_change_theme2'}
            />
          </div>
        </button>
      </div>
      {/* {end drawer} */}
    </>
  )
})
export default DrawerDags
