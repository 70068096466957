import React, {useState, useEffect} from 'react'
import axios from 'axios'

import Card from './components/Card'
import MainChart from './components/MainChart'
import RecentIssues from './components/RecentIssues'
import NewTechnologies from './components/NewTechnologies'
import Assets from './components/Assets'
import Earning from './components/Earning'
import BarChartAsset from './components/BarChartAsset'
import FPlus from './components/FPlus'
import HalfCircleChart from './components/HalfCircleChart'
import IssueByStatus from './components/IssueByStatus'
import Heatmap2 from './components/Heatmap2'
import {StatisticsWidget3} from '../../../_metronic/partials/widgets'
import sample from '../sample'
import ServerIcon from './imgs/ServerIcon'
import BugIcon from './imgs/BugIcon'
import PadLock from './imgs/PadLock'
import PageLoading from '../loading/PageLoading'
import {useDispatch, useSelector} from 'react-redux'
import {actions} from '../../modules/auth'
import useErrorHandling from '../../../Utils/useErrorHandling'

export const Dashboard1 = () => {
  const [loading, setLoading] = useState(false)
  const errorHandling = useErrorHandling()
  const dashboardData = useSelector((state) => state?.auth?.data?.dataDashboard)
  const dispatch = useDispatch()

  const fetchDashboardData = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/`, {
        withCredentials: true,
      })
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(actions.setDataDashboard(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/dashboard/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (dashboardData?.expireTime && dashboardData.expireTime >= nowDate && dashboardData?.data) {
      console.log('dashboardData \n we have data')
    } else {
      fetchDashboardData()
      console.log('dashboardData \n we get data')
    }
  }, [])
  const {
    card1,
    card2,
    card3,
    card4,
    vulnerAbilities,
    // recentIssues,
    // newTechnologies,
    zeroCard,
    // assetByGrade,
    earning,
    asset1,
    asset2,
    barChartAsset,
  } = sample

  return (
    <>
      {loading ? (
        <PageLoading />
      ) : dashboardData?.data ? (
        <div className='mx-auto' style={{maxWidth: 1320}}>
          <div className='row d-flex flex-row g-8 mb-8'>
            <FPlus>
              <HalfCircleChart title={card1?.title} percent={dashboardData.data?.total_score} />
            </FPlus>

            <Card
              bgColor='bg-Body'
              subHeader={card2.subHeader}
              header={dashboardData.data?.total_assets?.assets}
              icon={<ServerIcon />}
              chartColors={card2.chartColors}
              data={dashboardData.data?.total_assets?.assets_over_time?.y}
              categories={['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul']}
            />

            <Card
              bgColor='bg-Body'
              subHeader={card3.subHeader}
              header={dashboardData?.data?.total_vulnerabilities?.vulnerabilities}
              icon={<BugIcon />}
              chartColors={card3.chartColors}
              data={dashboardData?.data?.total_vulnerabilities?.vulnerabilities_over_time?.y}
              categories={['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul']}
            />

            <Card
              bgColor='bg-Body'
              subHeader={card4.subHeader}
              header={dashboardData?.data?.severe_vulnerabilities?.vulnerabilities}
              icon={<PadLock />}
              chartColors={card4.chartColors}
              data={dashboardData?.data?.severe_vulnerabilities?.vulnerabilities_over_time?.y}
              categories={['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul']}
            />
          </div>
          <div className='row d-flex flex-row g-8 mb-8'>
            <MainChart
              title={vulnerAbilities?.title}
              data={dashboardData?.data?.vulnerabilities_based_severity}
            />
            <RecentIssues recentIssues={dashboardData?.data?.recent_vulnerabilities} />
          </div>
          <div className='row d-flex flex-row g-8 mb-8'>
            <NewTechnologies newTechnologies={dashboardData?.data?.assets_at_risk} />
            <div className='col-4 position-relative' style={{height: 270}}>
              <div style={{position: 'absolute', left: 70, top: -11, zIndex: 2}}>
                <BugIcon width={30} height={30} color='#e74c3c' />
              </div>
              <StatisticsWidget3
                className='card-xl-stretch mb-xl-8 card card-body h-100'
                color='danger'
                title={zeroCard?.title}
                description={zeroCard?.desc}
                change={zeroCard?.value}
                data={zeroCard?.data}
              />
            </div>
            <IssueByStatus data={dashboardData?.data?.assets_by_grade} />
          </div>
          <div className='row d-flex flex-row g-8 mb-8'>
            <div className='col-6 h-500px'>
              <div className='row d-flex flex-row h-100 gx-8'>
                <div className='col-6 mb-8'>
                  {/* <div className='bg-body h-100 card' /> */}
                  {/* <Assets /> */}
                  <Earning data={earning?.data} />
                </div>

                <div className='col-6 mb-8'>
                  {/* <div className='bg-body h-100 card' /> */}
                  <Assets data={{...asset1, count: dashboardData?.data?.ssl_exp}} />
                </div>

                <div className='col-6'>
                  {/* <div className='bg-body h-100 card' /> */}
                  <Assets data={{...asset2, count: dashboardData?.data?.dns_score}} />
                </div>

                <div className='col-6'>
                  {/* <div className='bg-body h-100 card' /> */}
                  <BarChartAsset data={barChartAsset} />
                </div>
              </div>
            </div>
            <div className='col-6 h-500px'>
              <div className={`card card-body h-100 bg-body p-0`}>
                <Heatmap2 countries={dashboardData?.data?.host_by_country} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
