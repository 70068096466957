import React, { useLayoutEffect} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import InfoIcon from '../../images/InfoIcon'
import sample from '../../sample'
import './test.scss'
am4core.useTheme(am4themes_animated)

const Heatmap2 = ({
  countries
}) => {
  // const chart = useRef(null)
  const {heatMap} = sample

  useLayoutEffect(() => {
    // Create map instance
    let chart = am4core.create('chartDiv', am4maps.MapChart)

    // Set map definition
    chart.geodata = am4geodata_worldLow

    // Set projection
    chart.projection = new am4maps.projections.Mercator()

    // Create map polygon series
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true

    // Configure series
    let polygonTemplate = polygonSeries.mapPolygons.template
    polygonTemplate.tooltipText = '{name}: {value} Hosts'
    polygonTemplate.fill = am4core.color(heatMap.fillColor)
    polygonTemplate.stroke = am4core.color(heatMap.strokeColor)

    // Create hover state and set alternative fill color
    let hs = polygonTemplate.states.create('hover')
    hs.properties.fill = am4core.color(heatMap.hoverColor)

    // Remove Antarctica
    polygonSeries.exclude = ['AQ']

    // Add some data
    if (countries) {
      polygonSeries.data = countries
        .filter((country) => country.value)
        ?.map((country) => {
          return {
            id: country.ticker,
            value: country.value,
            fill: '#5398f8',
          }
        })
    }

    // Bind "fill" property to "fill" key in data
    polygonTemplate.propertyFields.fill = 'fill'

    return () => {
      chart.dispose()
    }
  }, [])

  return (
    <div className='card card-body' style={{width: '100%', height: '100%'}}>
      <div className='d-flex flex-column me-2'>
        <p className='d-flex   fw-bolder fs-3'>
          {heatMap.title}
          <p className='ms-2' data-tip={'test'}>
            <InfoIcon />
          </p>
        </p>
      </div>
      <div id='chartDiv' className='lll' style={{width: '100%', height: '100%'}}>
        <div />
      </div>
    </div>
  )
}
export default Heatmap2
