import domain from '../assets/www.png'
import ip from '../assets/ip.png'
import app from '../assets/app.png'
const Data = {
    metrics: [

        {
            name: 'Domains',
            icon: domain,
            ips: 111,
            live: [60, 40, 90, 80, 20, 40, 30],
            monitored: [60, 40, 90, 80, 20, 40, 30],
            ports: 157,
            total: 100,
            total_live: 100,
            total_monitored: 100,
            vulns: 8,
        },
        {
            name: 'IP',
            icon: ip,
            ips: 111,
            live: [60, 40, 90, 80, 20, 40, 30],
            monitored: [60, 40, 90, 80, 20, 40, 30],
            ports: 157,
            total: 100,
            total_live: 100,
            total_monitored: 100,
            vulns: 8,
        },
        {
            name: 'Applications',
            icon: app,
            ips: 111,

            total: 100,

            vulns: 8,
        },
    ]

}
const filterOption = [
    {
        title: "IP Addresses",
        data: [
            {
                name: '3.19.54.8',
                count: 14
            },
            {
                name: '3.23.174.230',
                count: 3
            },
            {
                name: '68.66.168.82',
                count: 12
            },
            {
                name: '3.18.44.60',
                count: 5
            },
            {
                name: '3.23.47.648',
                count: 7
            },
            {
                name: '329.783.872.73',
                count: 2
            },
        ]
    },
    {
        title: "Subdomains",
        data: [
            {
                name: 'ns1',
                count: 15
            },
            {
                name: 'ns2',
                count: 13
            },
            {
                name: 'www',
                count: 18
            },
            {
                name: 'autodiscover',
                count: 16
            },
            {
                name: 'blog',
                count: 11
            },
            {
                name: 'central',
                count: 14
            },
        ]
    },
    {
        title: "Country",
        data: [
            {
                name: 'IR',
                count: 20
            },
        ]
    },
    {
        title: "Services",
        data: [
            {
                name: 'https',
                count: 12
            },
            {
                name: 'http',
                count: 14
            },
            {
                name: 'ssh',
                count: 18
            },
            {
                name: 'upnp',
                count: 23
            },
            {
                name: 'ntp',
                count: 13
            },
            {
                name: 'revers-ssl',
                count: 22
            },
        ]
    },
]
export { Data , filterOption }