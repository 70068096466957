export const nodes = [
    {
        category: 'Recon',
        nodes: [
            {
                id: 'Asset Discovery',
                label: 'Asset Discovery',
            },
            {
                id: 'Waymore',
                label: 'Waymore',
            },
            {
                id: 'DNS',
                label: 'DNS',
            },
        ],
    },
    {
        category: 'OSINT',
        nodes: [
            {
                id: 'bbot',
                label: 'bbot',
            }
        ],
    },
    {
        category: 'Discovery',
        nodes: [
            {
                id: 'Crawler',
                label: 'Crawler',
            },
            {
                id: 'SMTP',
                label: 'SMTP',
            },
            {
                id: 'Screenshot',
                label: 'Screenshot',
            },
        ],
    },
    {
        category: 'Web Scanner',
        nodes: [
            {
                id: 'Acunetix',
                label: 'Acunetix',
            },
            {
                id: 'Burp Suite',
                label: 'Burp Suite',
            },
            {
                id: 'Invicti',
                label: 'Invicti',
            },
            {
                id: 'Core Impact',
                label: 'Core Impact',
            },
            {
                id: 'Cms Scanner',
                label: 'Cms Scanner',
            },
        ],
    },
    {
        category: 'Vulnerability Assessment',
        nodes: [
            {
                id: 'Greenbone',
                label: 'Greenbone',
            },
            {
                id: 'Nessus',
                label: 'Nessus',
            },
            {
                id: 'Vulners',
                label: 'Vulners',
            },
            {
                id: 'Nexpose',
                label: 'Nexpose',
            },
            {
                id: 'Metasploit',
                label: 'Metasploit',
            },
            {
                id: 'SSL',
                label: 'SSL',
            },
        ],
    },
    {
        category: 'Network',
        nodes: [
            {
                id: 'nmap',
                label: 'nmap',
            },
        ],

    },
    {
        category: 'Fuzzer',
        nodes: [
            {
                id: 'ffuf',
                label: 'ffuf',
            },
        ],
    },
    {
        category: 'Code Analysis',
        nodes: [
            {
                id: 'secretfinder',
                label: 'secretfinder',
            },
        ],
    },
    {
        category: 'Misconfiguration',
        nodes: [
            {
                id: 'Waf-Analyzer',
                label: 'Waf-Analyzer',
            },
            {
                id: 'DoS',
                label: 'DoS',
            },
        ]
    }
]

export const Table = [
    {
        id: 'passive_scan',
        name: 'Passive Scan',
        description: 'Enumerate all subdomains and check vulnerabilities using passive scan',
        created: '2023-06-17 15:15:12+00:00',
    },
    {
        id: 'asset_discovery',
        name: ' Asset Discovery',
        description: 'Enumerate all subdomains and asset',
        created: '2023-06-17 15:15:12+00:00',
    },
    {
        id: 'active_scan',
        name: 'Active Scan',
        description: 'Enumerate all subdomains and check vulnerabilities using active scan. this workflow could take a while',
        created: '2023-06-17 15:15:12+00:00',
    }
] 