import React from 'react'

const BarChartAsset = ({data}) => {
  const {
    header,
    headerRowTitle1,
    headerRowTitle2,
    headerRowColor,
    bodyRowsTitleColor,
    bodyRowsDataColor,
    chartData,
  } = data
  const maxChartWidth = Math.max(...chartData?.map((item) => item.clicks))

  return (
    <div className={`bg-body h-100 card`}>
      <div
        className={`w-100 h-50px p-4 fw-bolder d-flex justify-content-center align-items-center text-body bg-secondary rounded-top`}
      >
        {header}
      </div>
      <div className={`w-100 h-100 p-0 pt-4 d-flex flex-column align-items-center`}>
        <div className='w-100 d-flex flex-row row align-items-center mb-3'>
          <div className='col-7 fw-bold' style={{color: headerRowColor}}>
            {headerRowTitle1}
          </div>
          <div className='col-5 fw-bold' style={{color: headerRowColor}}>
            {headerRowTitle2}
          </div>
        </div>
        {chartData?.map((item, index) => (
          <div
            key={item.keyword}
            className='w-100 d-flex flex-row row align-items-center pt-3 pb-3'
            style={
              chartData.length !== index + 1
                ? {
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    borderStyle: 'dashed',
                    borderColor: headerRowColor,
                  }
                : {}
            }
          >
            <div style={{color: bodyRowsTitleColor}} className='col-7 fw-bolder'>
              {item.keyword}
            </div>
            <div className='col-5 d-flex flex-row align-items-center'>
              <div className='progress rounded-start-0 w-100'>
                <div
                  className='progress-bar m-0 d-flex align-items-center px-1'
                  role='progressbar'
                  style={{
                    height: 12,
                    width: `${(item.clicks / maxChartWidth) * 100}%`,
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                    backgroundColor: bodyRowsDataColor,
                    color: '#000',
                  }}
                >
                  {item.clicks}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BarChartAsset
