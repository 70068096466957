// import { CardNameType, Metrics } from '../../types'
import Chart from './chart/Chart'
import './Card.scss'
import arrow from './assets/Down Left Arrow.svg'
import {memo} from 'react'
import {useNavigate} from 'react-router-dom'
import Loc from '../../../images/Loc'
import Earth from '../../../images/Earth'
import Iphone from '../../../images/Iphone'
import Port from '../../../images/Port'
import Bug from '../../../images/Bug'

const Card = memo(function ({cardData}: {cardData: any}) {
  const navigate = useNavigate()
  return (
    <div
      className='card_custom bg-secondary card '
      style={{minWidth: 370}}
      onClick={() => {
        navigate({
          pathname: '/scope-filter',
          search: `?by=${cardData.name}`,
        })
      }}
    >
      <div className='card_hade'>
        <div className='card_icon'>
          <div className={'domain bg-body'}>
            {cardData.name === 'IP' ? (
              <Loc className='svg-icon svg-icon-5x image_change_theme2' />
            ) : cardData.name === 'Domains' ? (
              <Earth className={'svg-icon svg-icon-5x image_change_theme2'} />
            ) : (
              <Iphone className={'svg-icon svg-icon-5x image_change_theme2'} />
            )}
          </div>
          <span>{cardData.total}</span>
        </div>
        <img src={arrow} className='card_arrow image_change_theme' alt='arrow' />
      </div>
      <div className='card_name'>
        <strong>{cardData.name}</strong>
      </div>
      <hr />
      {cardData.total_live && (
        <>
          <div className='card_live'>
            <div className='live'>
              <div>
                <span>Live</span>
                <strong>{cardData.total_live}</strong>
              </div>
              <Chart data={cardData.live} total={cardData.total_live} />
            </div>
            <div className='live'>
              <div>
                <span>Monitored</span>
                <strong>{cardData.total_monitored}</strong>
              </div>
              <Chart data={cardData.monitored} total={cardData.total_monitored} />
            </div>
          </div>
          <hr />
        </>
      )}
      <div className='card_footer'>
        <div>
          <Earth className={'svg-icon svg-icon-4x image_change_theme2'}/>
          <div>
            <span>{cardData.name === 'IP' ? 'Domains' : 'IPs'}</span>
            <strong>{cardData.ips}</strong>
          </div>
        </div>
        {cardData.ports && (
          <div style={{justifyContent: 'center'}}>
           <Port className={'svg-icon svg-icon-4x image_change_theme2'}/>
            <div>
              <span>Ports</span>
              <strong>{cardData.ports}</strong>
            </div>
          </div>
        )}
        <div style={{justifyContent: 'flex-end'}}>
        <Bug className={'svg-icon svg-icon-4x image_change_theme2'}/>
          <div>
            <span>Vulns</span>
            <strong>{cardData.vulns}</strong>
          </div>
        </div>
      </div>
    </div>
  )
})
export default Card
