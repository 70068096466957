import React from 'react'

import DonutChart from './DonutChart'
import sample from '../../sample'
import InfoIcon from '../../images/InfoIcon'
import ReactTooltip from 'react-tooltip'
const DefaultData =[7, 0, 51, 8, 1]
const IssueByStatus = ({data=DefaultData}) => {
  const {
    tooltips: {AssetsByGroup},
  } = sample
  return (
    <div className='col-4' style={{height: 270}}>
      <div className={`card card-body h-100 bg-body`}>
        <div className='d-flex flex-row align-items-center'>
          <h3 className='card-title align-items-start flex-column   m-0'>
            Assets By Grade
          </h3>
          <p className='m-1' data-tip={AssetsByGroup}>
            <InfoIcon />
          </p>
          <ReactTooltip />
        </div>
        <DonutChart data={data} />
      </div>
    </div>
  )
}

export default IssueByStatus
