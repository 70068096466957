import React, {useState, useEffect} from 'react'
import axios from 'axios'

import NewTechnologies from './components/NewTechnologies'
import PageLoading from '../loading/PageLoading'
import ReactPaginate from 'react-paginate'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {actions} from '../../modules/auth'
export const Scope = () => {
  const [loading, setLoading] = useState(false)
  const scopeData = useSelector((state) => state?.auth?.data?.dataScope)
  const dispatch = useDispatch()
  const errorHandling = useErrorHandling()
  const handlePageClick = (event) => {
    fetchScoreData(event.selected + 1)
  }
  const fetchScoreData = async (page = 1) => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/targets/?page=${page}`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(actions.setDataScope(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/targets/?page=${page} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (scopeData?.expireTime && scopeData.expireTime >= nowDate) {
    } else {
      fetchScoreData()
    }
  }, [])
  return (
    <>
      {loading ? (
        <PageLoading />
      ) : scopeData?.data ? (
        <>
          <div className='row d-flex flex-row mt-5 '>
            <NewTechnologies scopeData={scopeData?.data?.results} />
          </div>
          <div className='d-flex justify-content-end bg-body pb-4 pe-4 rounded-bottom'>
            <ReactPaginate
              nextLabel='next >'
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={scopeData?.data?.total_pages}
              forcePage={scopeData?.data?.current_page-1}
              previousLabel='< previous'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
          </div>
        </>
      ) : null}
    </>
  )
}
