// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card_chart_box{
    display: flex;
    flex-direction: row !important;
    gap:5px;
    align-items: end;
    div{
        width: 5px;
        background-color: #4287c8;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/scope/components/cardComponent/chart/Chart.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,OAAO;IACP,gBAAgB;IAChB;QACI,UAAU;QACV,yBAAyB;QACzB,4BAA4B;QAC5B,2BAA2B;IAC/B;AACJ","sourcesContent":[".card_chart_box{\n    display: flex;\n    flex-direction: row !important;\n    gap:5px;\n    align-items: end;\n    div{\n        width: 5px;\n        background-color: #4287c8;\n        border-top-right-radius: 5px;\n        border-top-left-radius: 5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
