// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pass {
  border: 1px solid #8eff00;
  background-color: rgba(0, 255, 21, 0.08) !important;
  cursor: pointer;
}

.fail {
  border: 1px solid #ff0000;
  background-color: rgba(255, 0, 0, 0.1) !important;
  cursor: pointer;
}

.pass:hover {
  border: 1px solid #00ff99;
  background-color: rgba(0, 255, 21, 0.2) !important;
}

.fail:hover {
  border: 1px solid #ff4c4c;
  background-color: rgba(255, 0, 0, 0.2) !important;
}

.dns_items {
  transition: all 0.5s ease-in !important;
  max-height: 9000px !important;
}

.dns_items[show=false] {
  transition: all 0.5s ease-out !important;
  max-height: 0px !important;
}

.icon_items {
  rotate: -90deg !important;
}

.icon_items-90 {
  rotate: 90deg !important;
}

.transition {
  transition: all 0.3s ease-in-out !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/dnsInspect/components/style.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,mDAAA;EACA,eAAA;AACJ;;AACA;EACI,yBAAA;EACA,iDAAA;EACA,eAAA;AAEJ;;AAAA;EACI,yBAAA;EACA,kDAAA;AAGJ;;AADA;EACI,yBAAA;EACA,iDAAA;AAIJ;;AAFA;EACI,uCAAA;EACA,6BAAA;AAKJ;;AAHA;EACI,wCAAA;EAEA,0BAAA;AAKJ;;AAHA;EACI,yBAAA;AAMJ;;AAJA;EACI,wBAAA;AAOJ;;AALA;EACI,2CAAA;AAQJ","sourcesContent":[".pass{\n    border: 1px solid #8eff00;\n    background-color: rgb(0 255 21 / 8%) !important;\n    cursor: pointer;\n}\n.fail{\n    border: 1px solid #ff0000;\n    background-color: rgb(255 0 0 / 10%) !important;\n    cursor: pointer;\n}\n.pass:hover{\n    border: 1px solid #00ff99;\n    background-color: rgb(0 255 21 / 20%) !important;\n}\n.fail:hover{\n    border: 1px solid #ff4c4c;\n    background-color: rgb(255 0 0 / 20%) !important;\n}\n.dns_items{\n    transition: all 0.5s ease-in !important;\n    max-height: 9000px !important;    \n}\n.dns_items[show = 'false']{\n    transition: all 0.5s ease-out !important;\n\n    max-height: 0px !important;\n}\n.icon_items{\n    rotate: -90deg !important;\n}\n.icon_items-90{\n    rotate: 90deg !important;\n}\n.transition{\n    transition: all 0.3s ease-in-out !important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
