import React, {useEffect, useState} from 'react'
import image1 from './cardComponent/assets/mock/1.gif'
import image2 from './cardComponent/assets/mock/2.gif'
import image3 from './cardComponent/assets/mock/3.gif'
import image4 from './cardComponent/assets/mock/4.gif'
import image5 from './cardComponent/assets/mock/5.gif'
import image6 from './cardComponent/assets/mock/6.gif'
import image7 from './cardComponent/assets/mock/7.gif'
import image8 from './cardComponent/assets/mock/8.gif'
import image9 from './cardComponent/assets/mock/9.gif'
import image10 from './cardComponent/assets/mock/10.gif'
import LastStatus from 'react-last-status'
import Arr001 from '../../components/Arr001'
import prot from './cardComponent/assets/Conflict.svg'
import bug from './cardComponent/assets/Bug.svg'
import time from './cardComponent/assets/time.png'
import {useNavigate} from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import PageLoading from '../../loading/PageLoading'
import axios from 'axios'
import useErrorHandling from '../../../../Utils/useErrorHandling'

const colorConvertor = (grade) => {
  let color = ''
  color = grade === 'A' ? '#02694a' : color
  color = grade === 'B' ? '#255a8f' : color
  color = grade === 'C' ? '#9c8705' : color
  color = grade === 'D' ? '#75041e' : color
  color = grade === 'E' ? '#421919' : color
  color = grade === 'F' ? '#565672' : color

  return color
}
function randomImage () {
  const randomNumber = Math.floor(Math.random() * 10)
  switch (true) {
    case randomNumber === 1:
      return image1
    case randomNumber === 2:
      return image2
    case randomNumber === 3:
      return image3
    case randomNumber === 4:
      return image4
    case randomNumber === 5:
      return image5
    case randomNumber === 6:
      return image6
    case randomNumber === 7:
      return image7
    case randomNumber === 8:
      return image8
    case randomNumber === 9:
      return image9
    case randomNumber === 10:
      return image10
    default:
      return image1
  }
}
function randomPort () {
  const randomNumber = Math.random() * 10
  switch (true) {
    case randomNumber <= 3:
      return 443
    case randomNumber <= 6 && randomNumber > 3:
      return 80
    case randomNumber <= 10 && randomNumber > 6:
      return 88
    default:
      return 443
  }
}
export default function TargetItemCard ({params}) {
  const [loading, setLoading] = useState(false)
  const errorHandling = useErrorHandling()
  const [data, setData] = useState()
  const navigate = useNavigate()
  const handlePageClick = (event) => {
    fetchScoreData(`?page=${event.selected + 1}`)
  }
  const fetchScoreData = async (query = '') => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/targets/${query}`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        setData(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/targets/${query} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchScoreData()
  }, [])

  return loading ? (
    <PageLoading />
  ) : data ? (
    <>
      <div>
        {data?.results?.map((item, index) => (
          <div
            key={item.id}
            className={`p-2  w-100 m-0  h-auto bg-gray-200 rounded-1 d-flex flex-column justify-content-center align-items-start ${
              index === data?.length - 1 ? null : 'mb-3'
            }`}
          >
            <h2 className='text-start w-100 ps-4'>{item.target}</h2>
            <div className='w-100 h-110px d-flex  p-2 pt-1 pb-1 justify-content-between align-items-start'>
              <div className='d-flex gap-5 w-330px'>
                <div className='w-200px h-100px d-flex justify-content-start align-items-center  h-auto rounded-3 bg-secondary overflow-hidden'>
                  <div
                    className={`d-flex justify-content-center position-relative align-items-center w-100 h-100`}
                    style={{
                      backgroundImage: `url(${randomImage()})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                    }}
                  ></div>
                </div>

                <div className='w-120 p-1 mx-2 h-auto d-flex flex-column gap-2 justify-content-center'>
                  <div className='d-flex gap-1 align-items-center'>
                    <img src={prot} alt='port' className='w-30px image_change_theme' />
                    <spam>Port</spam>
                  </div>
                  <div
                    className='p-1 px-2 rounded-2 text-center bg-body'
                    style={{width: 'fit-content'}}
                  >
                    {randomPort()}
                  </div>
                </div>
              </div>
              <div className='w-300px h-100px p-1 ps-4 d-flex flex-column gap-3 border-start border-white justify-content-center'>
                <div className='d-flex gap-2 align-items-center h-23px '>
                  <img src={bug} alt='port' className='w-20px image_change_theme' />
                  <spam>Vulnerabilities</spam>
                </div>

                <div className='w-100 d-flex justify-content-center align-items-center p-1 h-auto'>
                  {/* critical */}
                  <div className='w-100 h-auto d-flex  align-items-center '>
                    <div className='w-15px h-15px me-2 rounded-3 border-icritical border border-2 tooltip_tt'>
                      <span class='tooltiptext_tt'>critical</span>
                    </div>
                    {item.vulnerabilities.critical}
                  </div>
                  {/* high */}
                  <div className='w-100 h-auto d-flex  align-items-center ms-2 '>
                    <div className='w-15px h-15px me-2 rounded-3 border-ihigh border border-2 tooltip_tt'>
                      <span class='tooltiptext_tt'>high</span>
                    </div>
                    {item.vulnerabilities.high}
                  </div>
                  {/* medium */}
                  <div className='w-100 h-auto d-flex  align-items-center ms-2 '>
                    <div className='w-15px h-15px me-2 rounded-3 border-imedium border border-2 tooltip_tt'>
                      <span class='tooltiptext_tt'>medium</span>
                    </div>
                    {item.vulnerabilities.medium}
                  </div>
                  {/* low */}
                  <div className='w-100 h-auto d-flex  align-items-center ms-2 '>
                    <div className='w-15px h-15px me-2 rounded-3 border-ilow border border-2 tooltip_tt'>
                      <span class='tooltiptext_tt'>low</span>
                    </div>
                    {item.vulnerabilities.low}
                  </div>
                  {/* info */}
                  <div className='w-100 h-auto d-flex  align-items-center ms-2 '>
                    <div className='w-15px h-15px me-2 rounded-3 border-iinfo border border-2 tooltip_tt'>
                      <span class='tooltiptext_tt'>info</span>
                    </div>
                    {item.vulnerabilities.info}
                  </div>
                </div>
              </div>
              <div className='w-200px h-100px p-1 ps-4 d-flex flex-column gap-3 border-start border-white justify-content-center'>
                <div className='d-flex gap-2 align-items-center h-23px '>
                  <img src={time} alt='port' className='w-20px image_change_theme' />
                  <spam>Last update</spam>
                </div>
                <div className='w-190 p-1  h-auto d-flex '>
                  <LastStatus timestamp={item.timestamp} />
                </div>
              </div>
              <div className='w-10 p-1 ms-2 h-100px d-flex justify-content-center align-items-center pe-3 justify-content-center'>
                <button
                  className={`col-auto border border-0  bg-gray-200 p-0 m-0`}
                  onClick={() => {
                    navigate('/assets')
                  }}
                >
                  <Arr001 fill={'#fff'} className={'image_change_theme'} />
                </button>
              </div>
            </div>
          </div>
        ))}

        <div className={`row d-flex flex-row  my-5 `}>
          <div
            className={`w-auto h-auto  ms-2 me-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-iinfo border border-2' />
            info
          </div>
          <div
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-ilow border border-2' />
            low
          </div>
          <div
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-imedium border border-2' />
            medium
          </div>
          <div
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-ihigh border border-2' />
            high
          </div>
          <div
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-icritical border border-2' />
            critical
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-end bg-body  pe-4 rounded-bottom'>
        <ReactPaginate
          nextLabel='next >'
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={data?.total_pages}
          forcePage={data?.current_page - 1}
          previousLabel='< previous'
          pageClassName='page-item'
          pageLinkClassName='page-link'
          previousClassName='page-item'
          previousLinkClassName='page-link'
          nextClassName='page-item'
          nextLinkClassName='page-link'
          breakLabel='...'
          breakClassName='page-item'
          breakLinkClassName='page-link'
          containerClassName='pagination'
          activeClassName='active'
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  ) : null
}
