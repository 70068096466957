import {useState} from 'react'
import LastStatus from 'react-last-status'

import './toltip.scss'
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css'
import CreateModal from './CreateModal'
import Reloade from '../../images/Reloade'
import Pause from '../../images/Pause'
import Trash from '../../images/Trash'
import axios from 'axios'
import {Alert} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'

const NewTechnologies = ({scanData, dagsData, getData}) => {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [errShow, setErrShow] = useState()
  async function stopScan (id) {
    await axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/scan/${id}/stop/`, {
        status: 'stop',
      })
      .then((res) => console.log(res))
      .catch((e) => console.log(e))
  }
  return (
    <>
      {/* start Alert */}
      <div
        className=' d-flex flex-column align-items-center position-absolute top-10 start-auto right-auto '
        style={{zIndex: 100, top: -45}}
      >
        <Alert show={show} variant='success' style={{width: 'fit-content'}}>
          <Alert.Heading
            className='w-100 d-flex gap-10  justify-content-between '
            style={{width: 'fit-content'}}
          >
            Scan created successfully
            <div onClick={() => setShow(false)}>
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon-2 image_change_theme2'
              />
            </div>
          </Alert.Heading>
        </Alert>
        <>
          <Alert show={!!errShow} variant='danger' style={{width: 'fit-content'}}>
            <Alert.Heading
              className='w-100  d-flex gap-10  justify-content-between '
              style={{width: 'fit-content'}}
            >
              {errShow}
              <div onClick={() => setErrShow(false)}>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 image_change_theme2'
                />
              </div>
            </Alert.Heading>
          </Alert>
        </>
      </div>
      {/* end Alert */}
      <div className='w-100 h-100'>
        <div className={`card card-body h-100 bg-body rounded-bottom-0`}>
          {/* start title */}
          <div className='w-100 pe-3 d-flex h-100px flex-row justify-content-between align-items-start pt-2'>
            <div className='w-100  d-flex  mb-10 justify-content-between align-items-center'>
              <h3 className=' '>Scan</h3>
              <button
                type='button'
                className='btn btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                disabled={loading}
                onClick={() => {
                  setErrShow(false)
                  setShow(false)
                }}
              >
                {loading ? 'Wait for create Scan ...' : 'Create New Scan'}
              </button>
            </div>
          </div>
          {/* start body */}
          {/* start titles */}
          <div className='w-100 m-0 pe-3 '>
            <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
              <div className='w-25 p-1 h-auto'> Target </div>
              <div className='w-25 p-1 ps-2 ms-5 h-auto'> Workflow </div>
              <div className='w-300px p-1 h-auto d-flex '>Description</div>
              <div className='w-100px d-flex justify-content-center p-1 ms-2 h-auto'>Rate</div>
              <div className='w-200px p-1 ms-2 h-auto d-flex justify-content-center'> Started </div>
              <div className='w-200px p-1 ms-2 h-auto d-flex justify-content-center'> Status </div>
              <div className='w-200px p-1 ms-2 h-auto d-flex justify-content-center pe-3'>
                {' '}
                Action{' '}
              </div>
            </div>
          </div>
          {/* end titles */}
          <div className={`w-100 h-100 overflow-visible  pe-3 m-0 `}>
            {scanData?.map((item, index) => {
              return (
                <div
                  key={item?.id}
                  className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex justify-content-center align-items-center ${
                    index === scanData?.length - 1 ? null : 'mb-3'
                  }`}
                >
                  <div className='w-100 d-flex p-2 pt-1 pb-1 justify-content-between align-items-center'>
                    <div className='w-25 d-flex  align-items-center p-1 h-auto'>
                      <div
                        className={`d-flex justify-content-center align-items-center position-relative`}
                      >
                        {item?.target}
                      </div>
                    </div>
                    <div className='w-25 p-1 ps-2 ms-5 h-auto'>
                      {' '}
                      {item?.dag_id === 'passive_scan_once'
                        ? 'Passive Scan'
                        : item?.dag_id === 'asset_discovery'
                        ? 'Asset Discovery'
                        : 'Active Scan'}
                    </div>
                    <div
                      className=' p-1 h-auto  '
                      style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width :300}}
                    >
                      {item?.dag_id === 'passive_scan_once'
                        ? 'Enumerate all subdomains and check vulnerabilities using passive scan'
                        : item?.dag_id === 'asset_discovery'
                        ? 'Enumerate all subdomains and asset'
                        : 'Enumerate all subdomains and check vulnerabilities using active scan. this workflow could take a while'}
                    </div>
                    <div className='w-100px d-flex justify-content-center align-items-center p-1 h-auto'>
                      {item?.rate}
                    </div>
                    <div className='w-200px p-1 ms-2 h-auto d-flex justify-content-center'>
                      <LastStatus timestamp={item?.start_time} />
                    </div>
                    <div className='w-200px p-1 ms-2 h-auto d-flex justify-content-center'>
                      {item?.status}
                    </div>
                    <div className='w-200px p-1 ms-2 h-auto d-flex justify-content-center gap-2 pe-3'>
                      <button
                        className={`col-auto border border-0 text-white bg-gray-200 p-0 m-0`}
                        disabled
                        onClick={() => {}}
                      >
                        <Reloade className='svg-icon-2x svg-icon-success' />
                      </button>
                      <button
                        className={`col-auto border border-0 text-white bg-gray-200 p-0 m-0`}
                        onClick={() => {
                          stopScan(item.id)
                        }}
                      >
                        <Pause className='svg-icon-2x svg-icon-warning' />
                      </button>
                      <button
                        className={`col-auto border border-0 text-white bg-gray-200 p-0 m-0`}
                        onClick={() => {}}
                      >
                        <Trash className='svg-icon-2x svg-icon-danger' />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {/* <!-- Modal--> */}
      <CreateModal
        dags={dagsData}
        getData={getData}
        setShow={setShow}
        setErrShow={setErrShow}
        setLoading={setLoading}
      />
    </>
  )
}

export default NewTechnologies
