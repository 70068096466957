import React from 'react'
import Chart from 'react-apexcharts'

const DonutChart = ({data = [43, 33, 1, 1, 25]}) => {
  const options = {
    labels: ['Grade: A', 'Grade: B', 'Grade: C', 'Grade: D', 'Grade: F'],
    colors: ['#02694a', '#255a8f', '#9c8705', '#c02407', '#75041e'],
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      fontSize: '14px',
      labels: {
        colors: '#92929F',
      },
    },
    chart: {
      type: 'donut',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: ['#ff003300'],
      width: 2,
      dashArray: 0,
    },
    plotOptions: {
      pie: {
        offsetY: 0,
        customScale: 1,
        donut: {
          size: '50%',
        },
      },
    },
  }

  const series = data.map(item =>{
    if(!!item){
      return item
    }else{
      return 1
    }
  })
console.log(series)
  return (
    <>
      <Chart  height={220} options={options} series={series} type='donut' />
    </>
  )
}

export default DonutChart
 


