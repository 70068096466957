import * as React from 'react'

const ServerIcon = ({color = '#565572'}) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24}>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M5 2h14a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Zm6 2a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2h-5ZM7 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z'
        fill={color}
        opacity={0.3}
      />
      <path
        d='M5 9h14a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2Zm6 2a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2h-5Zm-4 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM5 16h14a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2Zm6 2a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2h-5Zm-4 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z'
        fill={color}
      />
    </g>
  </svg>
)

export default ServerIcon
