import React, {useState, useEffect} from 'react'
import axios from 'axios'
import ReactPaginate from 'react-paginate'

import NewTechnologies from './components/NewTechnologies'
import PageLoading from '../loading/PageLoading'

import useErrorHandling from '../../../Utils/useErrorHandling'
import { Table } from './sample'

export const WorkFlow = () => {
  const [loading, setLoading] = useState(false)
  const [workflowData, setWorkflowData] = useState()
  const errorHandling = useErrorHandling()
  const handlePageClick = (event) => {
    // fetchWorkflowData(event.selected+1)
  }

  // const fetchWorkflowData = async (page=1) => {
  //   setLoading(true)
  //   try {
  //     const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/?page=${page}`)
  //     errorHandling(data.status)//logout when invalid token && redirect 404
  //     if (data.status >= 200 && data.status < 300) {
  //       setWorkflowData(data.data)
  //     } else {
  //       console.error(
  //         `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/?page=${page} \n`,
  //         data
  //       )
  //     }
  //     setLoading(false)
  //   } catch (e) {
  //     console.log(e)
  //     setLoading(false)
  //   }
  // }
  // useEffect(() => {
  //   fetchWorkflowData()
  // }, [])


  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
        <>
          <div className='row d-flex flex-row  mt-5'>
            <NewTechnologies
              workflowData={Table}
            />
          </div>
          <div className='d-flex justify-content-end bg-body pb-4 pe-4'>
          <ReactPaginate
              nextLabel='next >'
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={1}
              forcePage={0}
              previousLabel='< previous'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
          </div>
        </>
      )}
    </>
  )
}
