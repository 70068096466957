import React from 'react'

const FPlus = ({
  icon = <i className='bi bi-bookmarks-fill fs-2x' />,
  header = '',
  subHeader = '',
  bgColor = 'bg-body',
  subColor = ' ',
 children
}) => {
  return (
    <div className='d-flex flex-column h-150px col-3'>
      <div className={`h-100px card card-body justify-content-between ${bgColor}`}>
        <div className='row d-flex flex-row'>
          <div className='col-5'>
            {icon}
            <div className={`${subColor} fw-bolder fs-2 mt-5 mb-2`}>{header}</div>
            <div className='mt-5 pt-2 fw-bold text-gray-400'>{subHeader}</div>
          </div>

          <div className='col-7 d-flex justify-content-center align-items-center'>
            {children}
          </div>
        </div>
        {/* <div className='fw-bold text-gray-400'>{subHeader}</div> */}
      </div>
    </div>
  )
}

export default FPlus
