import React, {useState, useEffect} from 'react'
import axios from 'axios'
import PageLoading from '../loading/PageLoading'
import Section from './components/Section'
import {KTSVG} from '../../../_metronic/helpers'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {actions} from '../../modules/auth'
export const SMTP = () => {
  const [loading, setLoading] = useState(false)
  const [initialIndex, setInitialIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(null)
  const errorHandling = useErrorHandling()
  const smtpData = useSelector((state) => state?.auth?.data?.dataSmtp)
  const dispatch = useDispatch()
  const fetchSmtpIdList = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/smtp/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(actions.setDataSmtp(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/smtp \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (smtpData?.expireTime && smtpData.expireTime >= nowDate) {
    } else {
      fetchSmtpIdList()
    }
  }, [])

  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (smtpData?.data ?
        <div className='row d-flex flex-row mt-5'>
          <div className='w-100 h-100'>
            <div className={` h-100  `}>
              <div className='card card-body w-100 p-3 d-flex h-50px flex-row justify-content-between align-items-center  mb-5'>
                <button
                  className={`col-auto border border-0 text-white bg-body px-4 m-0`}
                  onClick={() => {
                    if (initialIndex > 0) {
                      setInitialIndex(initialIndex - 1)
                    }
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr002.svg'
                    className='svg-icon svg-icon-2x ms-2 svg-icon-white image_change_theme2'
                  />
                </button>

                <h3 className='  text-center m-0'>{`SMTP Inspect for ${smtpData?.data[initialIndex]?.data.host}`}</h3>

                <button
                  className={` col-auto border border-0 text-white bg-body px-4 m-0`}
                  onClick={() => {
                    if (initialIndex < smtpData?.data.length - 1) {
                      setInitialIndex(initialIndex + 1)
                    }
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr001.svg'
                    className='svg-icon svg-icon-2x ms-2 svg-icon-white image_change_theme2'
                  />
                </button>
              </div>
              <div className='d-flex wrap gap-5'></div>
              {Object.keys(smtpData?.data[initialIndex]?.data)
                .filter(
                  (item) =>
                    item !== 'host' &&
                    item !== 'score' &&
                    item !== 'timestamp' &&
                    item !== 'smtp_address'
                )
                ?.map((item) => smtpData?.data[initialIndex]?.data[item])
                ?.map((section, ind) => (
                  <Section
                    key={section?.title}
                    data={section}
                    ind={ind}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                  />
                ))}
            </div>
          </div>
        </div>:null
      )}
    </>
  )
}
