import {useState} from 'react'
import LastStatus from 'react-last-status'
import {useNavigate} from 'react-router-dom'

import {KTSVG} from '../../../../_metronic/helpers'
// import sample from '../../sample'
import Arr001 from '../../components/Arr001'
import Sort from '../../images/Sort'

const colorConvertor = (grade) => {
  let color = ''
  color = grade === 'A' ? '#02694a' : color
  color = grade === 'A+' ? '#02694a' : color
  color = grade === 'B' ? '#255a8f' : color
  color = grade === 'C' ? '#9c8705' : color
  color = grade === 'D' ? '#75041e' : color
  color = grade === 'F' ? '#421919' : color
  color = grade === 'T' ? '#421919' : color
  color = grade === 'M' ? '#421919' : color
  color = grade === 'N' ? '#565672' : color

  return color
}
const HostTest = [
  'wait for API 1',
  'wait for API 2',
  'wait for API 3',
  'wait for API 4',
  'wait for API 5',
]

const Section = ({data}) => {
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  function sort (sortItem) {
    switch (sortItem) {
      case 'severity':
        console.log('severity')
        break

      case 'affected_asset':
        console.log('affected_asset')
        break

      case 'last_seen':
        console.log('last_seen')
        break

      default:
        break
    }
  }
  return (
    <div className='w-100 h-100'>
      <div className={`card card-body h-100 bg-body rounded-bottom-0`}>
        {/* start title */}
        <div className='w-100 pe-3 d-flex flex-row justify-content-between align-items-start pt-2'>
          <h3 className=' '>SSL Report</h3>
        </div>
        {/* end title */}

        {/* start filters */}
        <div className='d-flex gap-5 my-10'>
          <div className='w-100'>
            <label className='form-label fw-bold'>Severity Filter:</label>

            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Severity Filter'
                data-allow-clear='true'
                defaultValue={false}
              >
                <option value={false}>No filter</option>
                <option value='Critical'>critical</option>
                <option value='high'>High</option>
                <option value='medium'>Medium</option>
                <option value='low'>Low</option>
                <option value='info'>Info</option>
              </select>
            </div>
          </div>
          <div className='w-100'>
            <label className='form-label fw-bold'>Sensor Filter:</label>

            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Sensor Filter'
                data-allow-clear='true'
                defaultValue={false}
              >
                <option value={false}>No filter</option>
                <option value='dns_sensor'>DNS Sensor</option>
                <option value='fuzzer'>Fuzzer</option>
                <option value='pscan'>Pscan</option>
                <option value='smtp_sensor'>Smtp Sensor</option>
                <option value='ssl'>SSL</option>
                <option value='tech'>Tech</option>
                <option value='web_scan'>Web Scan</option>
                <option value='wpscan'>Wpscan</option>
              </select>
            </div>
          </div>
          <div className='w-100'>
            <label className='form-label fw-bold'>Host Filter:</label>

            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Host Filter'
                data-allow-clear='true'
                defaultValue={false}
              >
                <option value={false}>No filter</option>
                {HostTest?.map((item, ind) => (
                  <option key={ind} value={ind}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='w-100'>
            <label className='form-label fw-bold'>Search</label>

            <div>
              <input
                type='text'
                className='form-control w-100 mw-300px form-control-solid text-gray-700'
                placeholder='Search'
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* end filters */}

        {/* start body */}
        {/* start titles */}
        <div className='w-100 m-0 pe-3 '>
          <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
            <div className='w-200px p-1 h-auto'> Grade </div>
            <div className='w-100 p-1 ps-2 ms-5 h-auto'>
              <div
                className='w-100  h-auto d-flex justify-content-start align-items-center pointer'
                onClick={() => {
                  sort('severity')
                }}
              >
                {' '}
                <span>Name</span>{' '}
                <Sort className='svg-icon svg-icon-1x ms-2 svg-icon-white '/>

              </div>
            </div>
            <div className='w-75 p-1 h-auto d-flex justify-content-center'>
              <div
                className='w-100  h-auto d-flex justify-content-center align-items-center pointer'
                onClick={() => {
                  sort('severity')
                }}
              >
                {' '}
                <span>Vulnerabilities</span>{' '}
               <Sort className='svg-icon svg-icon-1x ms-2 svg-icon-white '/>
              </div>
            </div>
            <div className='w-100 d-flex justify-content-center p-1 ms-2 h-auto'>
              <div
                className='w-100  h-auto d-flex justify-content-center align-items-center pointer'
                onClick={() => {
                  sort('severity')
                }}
              >
                {' '}
                <span>Certificate Status</span>{' '}
               <Sort className='svg-icon svg-icon-1x ms-2 svg-icon-white '/>
              </div>
            </div>
            <div className='w-100 p-1 ms-2 h-auto d-flex justify-content-center'>
              <div
                className='w-100 h-auto d-flex justify-content-center align-items-center pointer'
                onClick={() => {
                  sort('severity')
                }}
              >
                {' '}
                <span>Last Seen</span>{' '}
               <Sort className='svg-icon svg-icon-1x ms-2 svg-icon-white '/>
              </div>
            </div>
            <div className='w-50 p-1 ms-2 h-auto d-flex justify-content-center pe-3'> View </div>
          </div>
        </div>
        {/* end titles */}
        <div className={`w-100 h-100 overflow-auto pe-3 m-0`}>
          {data?.map((item, index) => {
            const {id, grade, host, total_vulnerabilities, timestamp, certificate_status} = item
            console.log(certificate_status)
            return (
              <div
                onClick={() => {
                  navigate({
                    pathname: '/ssl-details',
                    search: `?id=${id}`,
                  })
                }}
                key={`ssl_list_${index}`}
                className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex justify-content-center align-items-center pointer ${
                  index === data.length - 1 ? null : 'mb-3'
                }`}
              >
                <div className='w-100 d-flex p-2 pt-1 pb-1 justify-content-between align-items-center'>
                  <div className='w-200px d-flex justify-content-center align-items-center p-1 h-auto'>
                    <div
                      className={`d-flex justify-content-center align-items-center position-relative`}
                    >
                      {/* {getHexIcon(colorConvertor(grade))} */}
                      <KTSVG
                        path='/media/hexagonal.svg'
                        className='svg-icon svg-icon-3x ms-2 svg-icon-body'
                        fill={colorConvertor(grade)}
                      />
                      {/* <span style={{fill: '#ff0000'}} className='svg-icon'>
                          <img src='/media/hexagonal.svg' width='35px' alt='' />
                        </span> */}
                      <span className='position-absolute ms-2 text-black fw-bolder'>{grade}</span>
                    </div>
                  </div>
                  <div className='w-100 p-1 ps-2 ms-5 h-auto'> {host} </div>
                  <div className='w-75 p-1 h-auto d-flex justify-content-center'>
                    {total_vulnerabilities}
                  </div>
                  <div className='w-100 p-1 h-auto d-flex justify-content-center'>
                    {`${certificate_status}`}
                  </div>

                  <div className='w-100 p-1 ms-2 h-auto d-flex justify-content-center'>
                    <LastStatus
                      timestamp={timestamp}
                      // now={'[I am alive]'}
                      // sameDay={'[last seen today at] HH:mm'}
                      // lastDay={'[last seen yesterday at] HH:mm'}
                      // lastWeek={'[last seen] dddd [at] HH:mm'}
                      // sameElse={'[last seen] L [at] HH:mm'}
                    />
                  </div>
                  <div className='w-50 p-1 ms-2 h-auto d-flex justify-content-center pe-3'>
                    <button className={`col-auto border border-0 text-white bg-gray-200 p-0 m-0`}>
                      {/* <KTSVG
                          path='/media/icons/duotune/arrows/arr001.svg'
                          className='svg-icon svg-icon-2x ms-2 svg-icon-white'
                        /> */}
                      <Arr001 fill={'#fff'} className={'image_change_theme'} />
                    </button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {/* end body */}
      </div>
    </div>
  )
}

export default Section
