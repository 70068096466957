import React, {useState, useEffect} from 'react'
import LastStatus from 'react-last-status'
import {useSearchParams} from 'react-router-dom'
import axios from 'axios'

import PageLoading from '../../loading/PageLoading'
import useErrorHandling from '../../../../Utils/useErrorHandling'

const VulnerabilityDetails = () => {
  const [loading, setLoading] = useState(true)
  const [vulnerabilityDetailData, setAssetVulnerabilityData] = useState()
  const [searchParams] = useSearchParams()
  const errorHandling = useErrorHandling()
  const id = searchParams.get('id')
  useEffect(() => {
    setLoading(true)
    const fetchVulnerabilityDetailData = async () => {
      try {
        const data = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/${id}/`
        )
        errorHandling(data.status)//logout when invalid token && redirect 404
        if (data.status >= 200 && data.status < 300) {
          setAssetVulnerabilityData(data.data)
        } else {
          console.error(
            `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/${id} \n`,
            data
          )
        }
        setLoading(false)
      } catch (e) {
        console.log(e)
        setLoading(false)
      }
    }

    fetchVulnerabilityDetailData()
  }, [searchParams])

  const severityContent = (severity) => {
    switch (severity) {
      case 'info':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-iinfo me-3`} />
            <span className='m-auto'>Info Severity </span>
          </>
        )
      case 'low':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-ilow me-3`} />
            <span className='m-auto'>Low Severity </span>
          </>
        )

      case 'medium':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-imedium me-1`} />
            <span className='m-auto'>Medium Severity </span>
          </>
        )

      case 'high':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-ihigh me-3`} />
            <span className='m-auto'>High Severity </span>
          </>
        )

      case 'critical':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-icritical me-3`} />
            <span className='m-auto'>Critical Severity </span>
          </>
        )

      default:
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-white me-3`} />
            <span className='m-auto'>None</span>
          </>
        )
    }
  }
   function exportReport() {
    if(vulnerabilityDetailData){

      const element = document.createElement("a");
      const textFile = new Blob([JSON.stringify(vulnerabilityDetailData)], {type: 'application/json'}); 
      element.href = URL.createObjectURL(textFile);
      element.download = "Report.json" ;
      document.body.appendChild(element); 
      element.click();
    }
  }
  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
        vulnerabilityDetailData?
        <div className='w-100'>
          <div className={`card card-body h-100 bg-body`}>
            <div className='d-flex h-50px flex-row justify-content-between align-items-start pt-2'>
              <h3 className=' '>{vulnerabilityDetailData.title}</h3>
              <div className='d-flex gap-3'>
                <button className='btn btn-primary'>Send to AI Assisstant</button>
                <button className='btn btn-success' onClick={exportReport}>Check With Expert</button>
              </div>
            </div>

            <div className='px-3 py-1 d-flex mw-200px my-5 align-items-center bg-secondary rounded-3'>
              {severityContent(vulnerabilityDetailData.severity)}
            </div>
            <div className='w-100 d-flex align-items-center justify-content-between m-0 pe-3'>
              <div className='d-flex w-75 mw-700px justify-content-between align-items-center'>
                <div className='d-flex flex-column justify-content-start'>
                  <div className='p-1 text-gray-700 h-auto'>AFFECTED ENTITY </div>
                  <div className='w-200px p-1 mt-2 d-flex align-items-center text-primary h-auto'>
                    {vulnerabilityDetailData.host}
                  </div>
                </div>
                <div className='d-flex flex-column justify-content-start'>
                  <div className='w-200px p-1 text-gray-700 h-auto'>Targets </div>
                  <div className='w-200px p-1 mt-1 d-flex align-items-center text-gray-800 h-auto'>
                    <div
                      className={`w-30px h-30px bg-gray-500 text-white rounded-1 d-flex justify-content-center align-items-center p-1 me-3`}
                    >
                      {vulnerabilityDetailData.tag}
                    </div>
                    {vulnerabilityDetailData.target}
                  </div>
                </div>
                <div className='d-flex flex-column justify-content-start'>
                  <div className='min-w-200px p-1 text-gray-700 h-auto'>AFFECTED TECHNOLOGIES</div>
                  <div className='p-1 mt-2 text-gray-900'>{vulnerabilityDetailData.component}</div>
                </div>
              </div>

              <div className='d-flex flex-column justify-content-start'>
                <div className='w-150px p-1 text-gray-700 h-auto'> LAST SEEN </div>
                <span className='w-150px p-1 mt-2 text-gray-900 h-auto'>
                  <LastStatus
                    timestamp={vulnerabilityDetailData.timestamp}
                  />
                </span>
              </div>
            </div>

            <div className={`w-100 h-100 pe-3 m-0 mt-5`}>
              <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                <li className='nav-item'>
                  <a className='nav-link active' data-bs-toggle='tab' href={`#desc_tab`}>
                    Description
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link' data-bs-toggle='tab' href={`#remediation_tab`}>
                    Mitigation
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link ' data-bs-toggle='tab' href={`#ref_tab`}>
                    References
                  </a>
                </li>

                <li className='nav-item'>
                  <a className='nav-link' data-bs-toggle='tab' href='#poc'>
                    PoC
                  </a>
                </li>
              </ul>

              <div className='tab-content bg-mh_black h-250px p-3 overflow-auto' id='myTabContent'>
                <div className='tab-pane fade active show' id={`desc_tab`} role='tabpanel'>
                  <div dangerouslySetInnerHTML={{__html: vulnerabilityDetailData.detail}} />
                  {/* {vulnerabilityDetailData.detail} */}
                  {/* {detail} */}
                </div>
                <div className='tab-pane fade' id={`remediation_tab`} role='tabpanel'>
                  <div>{vulnerabilityDetailData.remediation}</div>
                </div>
                <div className='tab-pane fade' id={`ref_tab`} role='tabpanel'>
                  {vulnerabilityDetailData.references?.map((reference) => (
                    <div key={reference} className='col-6 p-2 mb-2 bg-secondary rounded-2'>
                      {reference}
                    </div>
                  ))}
                </div>

                <div className='tab-pane fade' id={`poc`} role='tabpanel'>
                  {/* <div dangerouslySetInnerHTML={{__html: vulnerabilityDetailData.poc}} /> */}
                  {vulnerabilityDetailData.poc !== null &&
                    console.log(Object.entries(vulnerabilityDetailData.poc))}
                  {vulnerabilityDetailData.poc !== null &&
                    Object.entries(vulnerabilityDetailData.poc)?.map((item) => (
                      <div id={item[0]} className='d-flex flex-column p-2'>
                        <div className='fs-4'>{item[0]}</div>
                        {/* <div className='px-2 mt-2'>{item[1]}</div> */}
                        {/* <div className='px-2 mt-2' dangerouslySetInnerHTML={{__html: item[1]}} /> */}
                        {item[1]}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        :null
      )}
    </>
  )
}

export default VulnerabilityDetails
