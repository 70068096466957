// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lll div svg g g:nth-child(2) g:nth-child(2) g g:nth-child(3)[aria-labelledby=id-79-title] {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/dashboard1/components/test.scss"],"names":[],"mappings":"AAO4B;EACI,aAAA;AANhC","sourcesContent":[".lll {\n    div {\n        svg {\n            g {\n                g:nth-child(2) {\n                    g:nth-child(2) {\n                        g {\n                            g:nth-child(3)[aria-labelledby=\"id-79-title\"] {\n                                display: none;\n                            }\n                        }\n                    }\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
