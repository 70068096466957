import * as React from 'react'

const InfoIcon = ({width = 15, height = 15 , }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 2400 2400'
    fill='#fff'
    className={'image_change_theme mx-2'}
  >
    <path d='M1162 .6C882.9 11.2 620.7 119 405.1 311.7c-22.6 20.2-73.2 70.8-93.4 93.4C151.5 584.4 48.9 797.8 13.6 1025.5 4.2 1086.2.6 1133.8.6 1200s3.6 113.8 13 174.5c35.3 227.7 137.9 441.1 298.1 620.4 20.2 22.6 70.8 73.2 93.4 93.4 169.9 151.8 371.4 252.4 585.4 292.1 73 13.6 132.7 19 209.5 19 76.8 0 136.5-5.4 209.5-19 202.5-37.6 395.5-130.5 559-269 47.2-40 102.9-95.7 142.9-142.9 159.5-188.3 256.8-412 282.1-649 4.6-43.7 5.9-68.5 5.9-119.5 0-76.8-5.4-136.5-19-209.5-39.7-214-140.3-415.5-292.1-585.4-20.2-22.6-70.8-73.2-93.4-93.4-142.7-127.6-305.8-218.1-483.3-268.2-78.5-22.2-155.7-35.4-238.6-41-21.5-1.5-91.1-2.6-111-1.9zm83 218.4c35.8 1.6 68.8 4.6 101 9.1 195.7 27.2 373.2 108.5 516.7 236.6 14 12.4 60.2 58.6 72.6 72.6 87.4 97.8 153.5 212.5 195.2 338.2 68.6 206.7 68.6 442.3 0 649-41.7 125.7-107.8 240.4-195.2 338.2-12.4 14-58.6 60.2-72.6 72.6-224 200-531.8 284.3-843.7 231.1-179.7-30.6-347.1-111-481.7-231.1-14-12.4-60.2-58.6-72.6-72.6-104.1-116.6-177.6-256.2-216.1-410.2-40.7-163.1-40.7-341.9 0-505 38.5-154 112-293.6 216.1-410.2 12.4-14 58.6-60.2 72.6-72.6C704.7 315.3 919 229.6 1151.5 219.1c26.5-1.2 67.9-1.2 93.5-.1z' />
    <path d='M1345.6 413.1c-49 5.2-90.2 30.4-118.7 72.7-23.2 34.5-31.8 78.2-23.9 121.9 8.9 49.4 42.7 93.7 89.5 117.1 36 17.9 81.7 20.9 122.5 8 47.5-15 88.3-55.6 104.5-104 10.1-30.3 10.6-70.2 1.2-101.3-14.6-48.2-54.2-89.6-101.8-106.3-21.5-7.6-49.2-10.6-73.3-8.1zM1203.5 952.9c-86.9 14.2-171.3 27.9-187.6 30.6l-29.6 4.8-6.6 20.7c-3.7 11.4-6.9 21.3-7.2 22.1-.4 1.2 4.3 1.4 29.2 1.4 34 .1 38.4.8 54.3 8.7 14.8 7.4 30.3 25.1 33.5 38.3 3.9 16.2 1.5 43-7.5 82-5.6 24.7-4.9 22.3-92.5 323.5-44.2 152.1-81.7 281.9-83.4 288.5-10 40.3-13.4 75.6-9.6 101 5.1 34.2 22.6 65 48.9 86.1 38 30.5 92.3 41.1 153.2 29.9 88.3-16.4 174-82.8 255.8-198.5 18.2-25.6 54.2-82.5 57-90 .5-1.1-4.2-4.3-18-12.4-10.2-6-18.9-10.5-19.3-10-.5.5-5.8 8.3-11.9 17.4-31.2 46.7-58.9 82.3-84.4 108.6-28.4 29.2-44.3 39.4-61.8 39.4-10.7 0-22.8-8.7-28.7-20.7-2.5-5.2-2.8-6.7-2.7-15.3.2-11.9 4.9-35.4 13.8-68.5 3.5-13.2 57.3-200.4 119.4-416 62.2-215.6 113.4-393.2 113.7-394.8l.7-2.7-35.3.1-35.4.1-158 25.7z' />
  </svg>
)

export default InfoIcon
