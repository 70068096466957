import {Data, filterOption} from './cardComponent/MockData/Data'
import {useNavigate, useSearchParams} from 'react-router-dom'
import TargetItemCard from './TargetItemCard'
import Loc from '../../images/Loc'
import Earth from '../../images/Earth'
import Iphone from '../../images/Iphone'

export default function TechFilter () {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const search = searchParams.get('by')
  return (
    <div className='d-flex w-100  mt-5 overflow-auto'>
      <div className='w-100px   d-flex flex-column align-items-center gap-10 pt-10 p-4 bg-gray-200'>
        {Data.metrics?.map((item, ind) => (
          <div
            className={`card_icon w-75px pb-2 h-auto card  ${
              search === item.name ? 'border border-3 border-primary' : ''
            }`}
            key={ind}
            onClick={() => {
              navigate({
                pathname: '/scope-filter',
                search: `?by=${item.name}`,
              })
            }}
          >
            <div className={' bg-body domain w-100 '}>
              {item.name === 'IP' ? (
                <Loc className='svg-icon svg-icon-5x image_change_theme2' />
              ) : item.name === 'Domains' ? (
                <Earth className={'svg-icon svg-icon-5x image_change_theme2'} />
              ) : (
                <Iphone className={'svg-icon svg-icon-5x image_change_theme2'} />
              )}
            </div>
            <span style={{fontSize: 10, textOverflow: 'hidden'}}>{item.name}</span>
          </div>
        ))}
      </div>
      <div className='w-100  p-4 bg-body '>
        <h1>{search}</h1>
        <div className='w-100  d-flex pt-5 position-relative'>
          <div className=' w-25   border-end border-gray pe-4 d-flex flex-column gap-4 '>
            {filterOption.map((items, index) => (
              <div key={index}>
                <h5 className='m-0'>{items.title}</h5>
                {items.data.map((item, ind) => (
                  <div
                    key={ind}
                    className='form-check form-check-custom form-check-solid form-check-sm py-2 border-top border-withe '
                  >
                    <input className='form-check-input' type='checkbox' value='' id='flexRadioLg' />
                    <label
                      className='form-check-label w-100 d-flex justify-content-between'
                      htmlFor='flexRadioLg'
                    >
                      <span>{item.name}</span>
                      <span>{item.count}</span>
                    </label>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className=' w-75 ps-5'>
            <div className={`w-100  overflow-visible pe-3 m-0 pb-8`}>
              <TargetItemCard params={search} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
