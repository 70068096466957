import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import LastStatus from 'react-last-status'
import './vulnerability.scss'
import Visibel from '../../images/Visibel'
import Sort from '../../images/Sort'
const HostTest = [
  'wait for API 1',
  'wait for API 2',
  'wait for API 3',
  'wait for API 4',
  'wait for API 5',
]
const NewTechnologies = ({vulnerabilityData, setId}) => {
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  function sort (sortItem) {
    switch (sortItem) {
      case 'severity':
        console.log('severity')
        break

      case 'affected_asset':
        console.log('affected_asset')
        break

      case 'last_seen':
        console.log('last_seen')
        break

      default:
        break
    }
  }
  return vulnerabilityData ? (
    <div className='w-100 h-100'>
      <div className={`card card-body h-100 bg-body rounded-bottom-0`}>
        {/* start title */}
        <div className='w-100 pe-3 d-flex  flex-row justify-content-between align-items-start pt-2'>
          <h3 className=' '>Vulnerabilities</h3>
        </div>
        {/* end title */}
        {/* start filters */}

        <div className='d-flex gap-5 my-10'>
          <div className='w-100'>
            <label className='form-label fw-bold'>Severity Filter:</label>

            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Severity Filter'
                data-allow-clear='true'
                defaultValue={false}
              >
                <option value={false}>No filter</option>
                <option value='Critical'>critical</option>
                <option value='high'>High</option>
                <option value='medium'>Medium</option>
                <option value='low'>Low</option>
                <option value='info'>Info</option>
              </select>
            </div>
          </div>
          <div className='w-100'>
            <label className='form-label fw-bold'>Sensor Filter:</label>

            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Sensor Filter'
                data-allow-clear='true'
                defaultValue={false}
              >
                <option value={false}>No filter</option>
                <option value='dns_sensor'>DNS Sensor</option>
                <option value='fuzzer'>Fuzzer</option>
                <option value='pscan'>Pscan</option>
                <option value='smtp_sensor'>Smtp Sensor</option>
                <option value='ssl'>SSL</option>
                <option value='tech'>Tech</option>
                <option value='web_scan'>Web Scan</option>
                <option value='wpscan'>Wpscan</option>
              </select>
            </div>
          </div>
          <div className='w-100'>
            <label className='form-label fw-bold'>Host Filter:</label>

            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Host Filter'
                data-allow-clear='true'
                defaultValue={false}
              >
                <option value={false}>No filter</option>
                {HostTest?.map((item, ind) => (
                  <option key={ind} value={ind}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='w-100'>
            <label className='form-label fw-bold'>Search</label>

            <div>
              <input
                type='text'
                className='form-control w-100 mw-300px form-control-solid text-gray-700'
                placeholder='Search'
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* end filters */}

        {/* start titles */}
        <div className='w-100 m-0 p-0 pe-3'>
          <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-1 align-items-center'>
            <div className='w-50 p-1 ps-2 h-auto'> Title </div>
            <div
              className='w-25 p-1 ps-4 ms-3 h-auto d-flex  align-items-center pointer'
              onClick={() => {
                sort('severity')
              }}
            >
              {' '}
              <span>severity</span> <Sort className='svg-icon svg-icon-1x ms-2 svg-icon-white ' />
            </div>
            <div
              className='w-25 p-1 ms-3 h-auto d-flex justify-content-center align-items-center pointer'
              // onClick={() => {
              //   sort('affected_asset')
              // }}
            >
              <span>Target</span>
              {/* <Sort  className='svg-icon svg-icon-1x ms-2 svg-icon-white ' /> */}
            </div>
            <div
              className='w-25 p-1 ms-3 h-auto d-flex justify-content-center align-items-center pointer'
              onClick={() => {
                sort('affected_asset')
              }}
            >
              <span>Affected Asset</span>
              <Sort className='svg-icon svg-icon-1x ms-2 svg-icon-white ' />
            </div>
            <div
              className='w-25 p-1 ms-3 h-auto d-flex justify-content-center align-items-center pointer'
              onClick={() => {
                sort('last_seen')
              }}
            >
              <span>Last Seen</span>
              <Sort className='svg-icon svg-icon-1x ms-2 svg-icon-white ' />
            </div>

            <div className='w-50px p-0 ms-2 h-auto d-flex justify-content-center pe-3'> View </div>
          </div>
        </div>
        {/* end titles */}
        {/* start body */}
        <div className={`w-100 h-100 overflow-auto pe-3 m-0`}>
          {vulnerabilityData &&
            vulnerabilityData?.map((item, index) => (
              <div
                key={item.id}
                onClick={() => {
                  setId(item.id)
                }}
                className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex align-items-center ${
                  index === vulnerabilityData.length - 1 ? null : 'mb-3'
                } hover_Costume`}
                style={{cursor: 'pointer', minHeight: 64}}
              >
                <div className='w-100 d-flex p-1 align-items-center'>
                  <div className='w-50 p-1 h-auto d-flex align-items-center'> {item.title} </div>
                  <div className='w-25 p-1 ms-3 h-auto d-flex flex-row align-items-center  text-capitalize'>
                    <div className=' d-flex justify-content-end'>
                      <div
                        className={`w-15px h-15px me-2 rounded-3 border-i${item.severity} border border-2 `}
                      />
                    </div>
                    {item.severity}
                  </div>
                  <span
                    className='w-25 p-1 ms-3 h-auto d-flex flex-row align-items-center justify-content-center '
                  >
                    {item.target}
                  </span>
                  <div className='w-25 p-1 ms-3 h-auto d-flex justify-content-center align-items-center'>
                    {item.host}
                  </div>
                  <div className='w-25 p-1 ms-3 h-auto d-flex justify-content-center align-items-center'>
                    <LastStatus timestamp={item.timestamp} />
                  </div>
                  <button
                    className={`w-50px border border-0 text-white bg-gray-200 p-0 ms-2 pe-3 d-flex-justify-content-center visible_icon`}
                    onClick={() => {
                      navigate({
                        pathname: '/vulnerability-details',
                        search: `?id=${item.id}`,
                      })
                    }}
                  >
                    <Visibel className='svg-icon svg-icon-2x ms-2 svg-icon-white image_change_theme2' />
                  </button>
                </div>
              </div>
            ))}
        </div>
        {/* end body */}
      </div>
    </div>
  ) : null
}

export default NewTechnologies
