import * as React from 'react'

const BugIcon = ({width = 24, height = 24, color = '#565572'}) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 90 90'>
    <path
      fill={color}
      d='M27.4 10.4c-.7 1.7 1.7 6.5 4.5 9l2 1.9-2.3 1.8c-3 2.4-3.7 2.4-6.9-.2-2-1.5-3.8-1.9-7.4-1.7-4.4.3-4.8.5-4.8 2.8 0 2.1.6 2.6 4 3.1 2.2.3 6.3 1.7 9 3 4.6 2.2 6.3 2.4 19.5 2.4s14.9-.2 19.5-2.4c2.8-1.3 6.8-2.7 9-3 3.4-.5 4-1 4-3.1 0-2.3-.4-2.5-4.8-2.8-3.6-.2-5.4.2-7.4 1.7-3.2 2.6-3.9 2.6-6.9.2l-2.3-1.8 2-1.9c2.8-2.5 5.2-7.3 4.5-9-.9-2.3-4.5-1.6-5.8 1.1-1.8 4.1-5 5.6-8.7 4.2-2.4-.9-3.8-.9-6.2 0-3.7 1.4-6.9-.1-8.7-4.2-1.3-2.7-4.9-3.4-5.8-1.1zM11.5 34.9c-3.7 1.3-5.1 2.3-5.3 3.9-.5 3.1 2 3.7 7.4 1.8 5.5-1.9 6.7-1.4 5.4 2-1.3 3.3-1.3 10 .1 14.7 1.7 6.1.4 9.1-5.2 12.8-3.8 2.4-4.9 3.7-4.7 5.3.2 1.1 1.1 2.2 2 2.4 1.8.3 12.8-6.5 12.8-8 0-.4 2.2.9 5 3.1 5.1 4 10.5 6 12.1 4.4.5-.5 1-7.8 1.1-16.3l.3-15.5h5l.3 15.5c.1 8.5.6 15.8 1.1 16.3 1.6 1.6 7-.4 12.1-4.4 2.8-2.2 5-3.5 5-3.1 0 1.5 11 8.3 12.8 8 .9-.2 1.8-1.3 2-2.4.2-1.6-.9-2.9-4.7-5.3-5.6-3.7-6.9-6.7-5.2-12.8 1.4-4.7 1.4-11.4.1-14.7-1.3-3.4-.1-3.9 5.4-2 5.4 1.9 7.9 1.3 7.4-1.8-.2-1.6-1.7-2.6-5.8-4l-5.5-1.9-8 2.8c-7.1 2.5-9.3 2.8-20 2.7-10.5 0-12.9-.3-19.1-2.7-8.2-3.1-7.4-3.1-13.9-.8z'
    />
  </svg>
)

export default BugIcon
