import React, {useState, useEffect} from 'react'
import axios from 'axios'

import NewTechnologies from './components/NewTechnologies'
import PageLoading from '../loading/PageLoading'
import ReactPaginate from 'react-paginate'
import useErrorHandling from '../../../Utils/useErrorHandling'

export const Scan = () => {
  const [loading, setLoading] = useState(false)
  const [scanData, setScanData] = useState([])
  const [dags, setDags] = useState([])

  const errorHandling = useErrorHandling()
  const handlePageClick = (event) => {
    fetchScanData(event.selected + 1)
  }
  const fetchDagsData = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dags/`)
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        setDags(data.data)
      } else {
        console.error(`error in get data from ${process.env.REACT_APP_API_ENDPOINT}/dags/ \n`, data)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const fetchScanData = async (page = 1) => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/scan/`)
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        setScanData(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/scan/?page=${page} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    console.log('object')
    fetchScanData()
    fetchDagsData()
  }, [])

  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
        <>
          <div className='row d-flex flex-row  mt-5  position-relative'>
            <NewTechnologies scanData={scanData} dagsData={dags} getData={fetchScanData} />
          </div>
          <div className='d-flex justify-content-end bg-body pb-4 pe-4 rounded-bottom'>
            {/* <ReactPaginate
              nextLabel='next >'
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={scanData?.total_pages}
              forcePage={scanData?.current_page - 1}
              previousLabel='< previous'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            /> */}
          </div>
        </>
      )}
    </>
  )
}
