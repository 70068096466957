/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
// import {login} from '../redux/AuthCRUD'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import axios from 'axios'
import {Dispatch} from 'react'


const loginSchema = Yup.object().shape({
  email: Yup.string()
    // .email('Wrong email format')
    // .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}
interface fetchLoginValueProps {
  email: string
  password: string
}
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login () {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const fetchLoginToken = async (
    values: fetchLoginValueProps,
    setStatus: Dispatch<any>,
    setSubmitting: Dispatch<any>
  ) => {
    try {
      const data = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/jwt/create/`, {
        email: values.email,
        password: values.password,
      },{withCredentials : true})
      if (data?.status >= 200 && data.status < 300) {
        dispatch(auth.actions.login("true"))
      }else{
        console.error(`error in login \n`,data)
      }
      setLoading(false)
      
    } catch (e) {
      console.log(e)
      setLoading(false)
      setSubmitting(false)
      setStatus('The login detail is incorrect')
    }
  }

  

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (
      values: fetchLoginValueProps,
      {setStatus, setSubmitting}: {setStatus: Dispatch<any>; setSubmitting: Dispatch<any>}
    ) => {
      setLoading(true)
      fetchLoginToken(values, setStatus, setSubmitting)
    },
  })

  return (
    <form
      className='w-100 form'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      {/* <div className='mb-10 text-center'>
        <h1 className='mb-3 text-dark'>Sign In to Metronic</h1>
        <div className='fw-bold text-gray-400 fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='fw-bolder link-primary'>
            Create an Account
          </Link>
        </div>
      </div> */}
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='font-weight-bold alert-text'>{formik.status}</div>
        </div>
      ) : (
        <div className='d-flex justify-content-center flex-column align-items-center mb-10 p-8 rounded'>
          <h1>Sign In To Admin</h1>
          <span className='opacity-75' >Enter your details to login to your account</span>
        </div>
      )}

      {/* begin::Form group */}
      <div className='mb-10 fv-row'>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
          style={{opacity: 0.7, backgroundColor: 'rgba(24, 28, 50, 0.28)'}}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container text-center text-danger'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='mb-10 fv-row'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}

            {/* end::Label */}
            {/* begin::Link */}
            {/* <Link
              to='/auth/forgot-password'
              className='fw-bolder fs-6 link-primary'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link> */}
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5 ',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
          style={{opacity: 0.7, backgroundColor: 'rgba(24, 28, 50, 0.28)'}}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container  text-center text-danger'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='form-group d-flex flex-wrap justify-content-between align-items-center px-8'>
        <div className='form-check form-check-inline'>
          <input
            className='form-check-input bg-transparent border border-white '
            type='checkbox'
            id='flexCheckChecked'
          />
          <label className='checkbox checkbox-outline checkbox-white text-white m-0'>
            Remember me
          </label>
        </div>
        <Link to='/auth/forgot-password' id='kt_login_forgot' className='text-white font-weight-bold'>
          Forget Password ?
        </Link>
      </div>
      {/* begin::Action */}
      <div className='text-center mt-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-pill border border-white rounded-pill btn-outline-white font-weight-bold  px-15 py-3'
          disabled={formik.isSubmitting || !formik.isValid}
          style={{opacity: 0.9}}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}
        {/* <div className='mb-5 fw-bolder text-center text-muted text-uppercase'>or</div> */}
        {/* end::Separator */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn-flex flex-center mb-5 w-100 btn btn-lg btn-light'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn-flex flex-center mb-5 w-100 btn btn-lg btn-light'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn-flex flex-center w-100 btn btn-lg btn-light'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  )
}
