import React from 'react'
import {useNavigate} from 'react-router-dom'
import LastStatus from 'react-last-status'

// import {KTSVG} from '../../../../_metronic/helpers'
import sample from '../../sample'
import InfoIcon from '../../images/InfoIcon'
import ReactTooltip from 'react-tooltip'
import Arr001 from '../../components/Arr001'

const RecentIssues = ({recentIssues}) => {
  const navigate = useNavigate()
  const {
    tooltips: {recentVulnerabilities},
  } = sample

  return (
    <div className='col-6 h-400px'>
      <div className={`card card-body h-100 bg-body`}>
        {/* start title */}
        <div className='d-flex flex-row justify-content-between align-items-center pt-2 pb-5'>
          <div className='d-flex flex-row align-items-center'>
            <h3 className='card-title align-items-start flex-column   m-0'>
              Recent Vulnerabilities
            </h3>
            <p className='m-1' data-tip={recentVulnerabilities}>
              <InfoIcon />
            </p>
            <ReactTooltip />
          </div>
          <button
            className={`col-auto border border-0  bg-body p-0 m-0`}
            onClick={() => {
              navigate('/vulnerabilities')
            }}
          >
            View All{' '}
            {/* <KTSVG
              path='/media/icons/duotune/arrows/arr001.svg'
              className='svg-icon svg-icon-2x ms-2 svg-icon-white'
            /> */}
            <Arr001 fill={'#fff'} className={'image_change_theme'}/>
          </button>
        </div>
        {/* start body */}
        <div className={`w-100 h-100 overflow-auto pe-2 m-0`}>
          {recentIssues?.map((issue, index) => (
            <div
              key={issue?.id}
              className={`d-flex flex-row w-100 h-55px bg-secondary p-2 border border-box m-0 align-items-center rounded-3 ${
                index === recentIssues.length - 1 ? null : 'mb-3'
              } `}
            >
              <div
                className={`w-30px h-30px bg-body   rounded-1 d-flex justify-content-center align-items-center text-uppercase p-0 m-0`}
              >
                {issue?.tag}
              </div>
              <div className='w-1px h-75 gx-0 bg-dark me-3 ms-3' />
              <div
                className={`w-15px h-15px rounded-3 border border-2 border-${`i${issue?.severity}`}`}
              />

              <div className={`me-auto ms-2 mw-200px text-truncate`}>{issue?.title}</div>
              <div className={`w-auto p-0 m-0 text-uppercase`}>
                <LastStatus
                  timestamp={issue?.timestamp}
                  // now={'[I am alive]'}
                  // sameDay={'[last seen today at] HH:mm'}
                  // lastDay={'[last seen yesterday at] HH:mm'}
                  // lastWeek={'[last seen] dddd [at] HH:mm'}
                  // sameElse={'[last seen] L [at] HH:mm'}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default RecentIssues
