import {useCallback, useState} from 'react'

import ReactFlow, {
  addEdge,
  Controls,
  MarkerType,
  useNodesState,
  useEdgesState,
} from 'react-flow-renderer'
import CustomEdge from './CustomEdge'
import './WorkFlow.scss'
import {KTSVG} from '../../../../_metronic/helpers'
import ModalSchedule from './ModalSchedule'
import {useSearchParams} from 'react-router-dom'
import CustomNode from './CustomNode'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import DrawerDags from './DrawerDags'

const loginSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  description: Yup.string().required('Description is required'),
})

const initialValues = {
  name: '',
  description: '',
}

const edgeTypes = {
  custom: CustomEdge,
}
const nodeTypes = {
  custom: CustomNode,
}
const initialNodes = [
  {
    id: '1',
    type: 'input',
    data: {label: <div className='label'>Initial</div>},
    position: {y: 250, x: 0},
    sourcePosition: 'right',
    className: 'large_item',
  },
  {
    id: '2',
    type: 'output',
    data: {label: <div className='label'>DNS</div>},
    position: {y: 100, x: 200},
    targetPosition: 'left',
    className: 'large_item',
  },
  {
    id: '3',
    type: 'output',
    data: {label: <div className='label'>SMTP</div>},
    position: {y: 400, x: 200},
    targetPosition: 'left',
    className: 'large_item',
  },
  {
    id: '4',
    data: {label: <div className='label'>Assets</div>},
    position: {y: 250, x: 200},
    sourcePosition: 'right',
    targetPosition: 'left',
    className: 'large_item',
  },
  {
    id: '5',
    type: 'output',
    data: {label: <div className='label'>PScan</div>},
    position: {y: 100, x: 500},
    targetPosition: 'left',
    className: 'large_item',
  },
  {
    id: '6',
    type: 'output',
    data: {label: <div className='label'>IP Info</div>},
    position: {y: 200, x: 500},
    targetPosition: 'left',
    className: 'large_item',
  },
  {
    id: '7',
    data: {label: <div className='label'>lf</div>},
    position: {y: 300, x: 500},
    sourcePosition: 'right',
    targetPosition: 'left',
    className: 'large_item',
  },
  {
    id: '8',
    type: 'output',
    data: {label: <div className='label'>SSL</div>},
    position: {y: 400, x: 500},
    targetPosition: 'left',
    className: 'large_item',
  },
  {
    id: '9',
    type: 'output',
    data: {label: <div className='label'>Screen Shot</div>},
    position: {y: 300, x: 700},
    targetPosition: 'left',
    className: 'large_item',
  },
]

const initialEdges = [
  {
    id: '1-2',
    source: '1',
    sourceHandle: null,
    target: '2',
    targetHandle: null,
  },
  {
    id: '1-3',
    source: '1',
    target: '3',
  },
  {
    id: '1-4',
    source: '1',
    target: '4',
  },
  {
    id: '4-5',
    source: '4',
    target: '5',
  },
  {
    id: '4-6',
    source: '4',
    target: '6',
  },
  {
    id: '4-7',
    source: '4',
    target: '7',
  },
  {
    id: '4-8',
    source: '4',
    target: '8',
  },
  {
    id: '7-9',
    source: '7',
    target: '9',
  },
]

const DAG = () => {
  const [searchParams] = useSearchParams()
  console.log(searchParams.get('id'))
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    initialEdges?.map((item) => {
      item.markerEnd = {type: MarkerType.Arrow}
      item.style = {cursor: 'pointer'}
      return item
    })
  )

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({...params, markerEnd: {type: MarkerType.Arrow}, style: {cursor: 'pointer'}}, eds)
      ),
    []
  )
  function addNode (item) {
    const copy = [...nodes]
    copy.push(item)
    setNodes(copy)
  }
  function dragHandler (item) {
    setSelectedNodeItem(item)
  }
  function dropHandler (e) {
    const {x, y} = e.target.getBoundingClientRect()
    const item = {
      ...selectedNodeItem,
      data: {label: <div className='label'>{selectedNodeItem.label}</div>},
      position: {x: e.nativeEvent.layerX - x / 2, y: e.nativeEvent.layerY - y / 2},
      id: Math.random()?.toString(),
    }
    if (selectedNodeItem.type === 'input') {
      item.sourcePosition = 'right'
    } else if (selectedNodeItem.type === 'output') {
      item.targetPosition = 'left'
    } else {
      item.targetPosition = 'left'
      item.sourcePosition = 'right'
    }
    addNode(item)
  }

  const [show, setShow] = useState(true)
  const [selectedNodeItem, setSelectedNodeItem] = useState()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  })
  return (
    <>
      {/* {start drawer} */}
      <DrawerDags dragHandler={dragHandler} />
      {/* {end drawer} */}

      {/* {start toolkit} */}
      <div className='workflow_toolkit'>
        <div className='tooltip_tt'>
          <KTSVG path={'/media/play.svg'} className={'svg-icon-1 svg-icon-success'} />
          <span className='tooltiptext_tt'>Execute</span>
        </div>
        <div
          type='button'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_Schedule'
          className='tooltip_tt'
        >
          <KTSVG path={'/media/time.svg'} className={'svg-icon-1 svg-icon-primary'} />
          <span className='tooltiptext_tt'>Schedule</span>
        </div>
        <div className='tooltip_tt'>
          <KTSVG path={'/media/save.svg'} className={'svg-icon-1 svg-icon-warning'} />
          <span className='tooltiptext_tt'>Save</span>
        </div>
        <div
          className='tooltip_tt'
          onClick={() => {
            setEdges(initialEdges)
            setNodes(initialNodes)
          }}
        >
          <KTSVG path={'/media/reset.svg'} className={'svg-icon-1 svg-icon-danger'} />
          <span className='tooltiptext_tt'>Reset</span>
        </div>
        <div
          className='tooltip_tt'
          type='button'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_edit'
        >
          <KTSVG path={'/media/edit.svg'} className={'svg-icon-1 svg-icon-info'} />
          <span className='tooltiptext_tt'>Edit</span>
        </div>
      </div>
      {/* {end toolkit} */}
      <h2 className='position-absolute top-100px'>
        Name : {` ${searchParams.get('id')}`}
      </h2>
      {/* {start workflow} */}
      <div style={{width: '100%', height: '100% '}}>
        <ReactFlow
          onDragOver={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onDrop={(e) => {
            e.preventDefault()
            e.stopPropagation()
            console.log(e)
            dropHandler(e)
          }}
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          snapToGrid={true}
          edgeTypes={edgeTypes}
          fitView
          // attributionPosition="top-right"
        >
          {/* <Background />*/}
          <Controls />
        </ReactFlow>
      </div>
      {/* {end workflow} */}
      <ModalSchedule />
      {/* {edit Workflow} */}
      <>
        <div className='modal fade' id='kt_modal_edit' style={{backdropFilter: 'blur(3px)'}}>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h3 className='modal-title'>Edit</h3>

                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon-2 image_change_theme2'
                  />
                </div>
              </div>

              <div className='modal-body'>
                <form
                  className='w-100 form'
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder  '>Name</label>
                    <input
                      type='text'
                      className={`form-control w-100  form-control-solid text-gray-700 ${clsx(
                        'form-control form-control-lg form-control-solid',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}`}
                      placeholder='Name'
                      {...formik.getFieldProps('name')}
                      name='name'
                    />

                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder  '>Description</label>
                    <textarea
                      className={`form-control w-100  form-control-solid text-gray-700 ${clsx(
                        'form-control form-control-lg form-control-solid',
                        {'is-invalid': formik.touched.description && formik.errors.description},
                        {
                          'is-valid': formik.touched.description && !formik.errors.description,
                        }
                      )}`}
                      placeholder='Description'
                      {...formik.getFieldProps('description')}
                      name='description'
                    />

                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    )}
                  </div>
                </form>
              </div>

              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    formik.resetForm()
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export default DAG
