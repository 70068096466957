import React, { memo } from 'react';
import { Handle, Position } from 'react-flow-renderer';
// import { Handle, Position } from '@xyflow/react';

const DEFAULT_HANDLE_STYLE = {
  width: 10,
  height: 10,
  bottom: -5,
};

export default memo(({ data, isConnectable }) => {
    console.log(isConnectable)
    console.log(data)
  return (
    <>
      <div
      className='large_item'
      >
        {/* <div>Node</div> */}
        <Handle
          type="source"
          id="red"
          position={Position.Right}
          style={{ ...DEFAULT_HANDLE_STYLE, top: '15%', background: 'red' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position={Position.Right}
          id="blue"
          style={{ ...DEFAULT_HANDLE_STYLE, top: '50%', background: 'blue' }}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position={Position.Right}
          
          id="orange"
          style={{ ...DEFAULT_HANDLE_STYLE, top: '85%', background: 'orange' }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position={Position.Left}
          id="orange"
          style={{ ...DEFAULT_HANDLE_STYLE, top: '85%', background: 'orange' }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position={Position.Left}
          id="red"
          style={{ ...DEFAULT_HANDLE_STYLE, top: '50%', background: 'red' }}
          isConnectable={isConnectable}
        />
      </div>
    </>
  );
});