
import axios from 'axios';
import { useIntl } from 'react-intl';
// import {KTIcon} from '../../../helpers'

const SidebarFooter = () => {
  const intl = useIntl()

  const getReport =  async(format:string) => {
    const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/reports/${format}/`)
    if(format === 'html') {
      var htmlContent = [`${data.data}`];
      var bl = new Blob(htmlContent, {type: "text/html"});
      var a = document.createElement("a");
      a.href = URL.createObjectURL(bl);
      a.download = "Report.html";
      a.hidden = true;
      document.body.appendChild(a);
      a.click();
    } else {
      const element = document.createElement("a");
      const textFile = new Blob([JSON.stringify(data.data)], {type: 'text/plain'}); //pass data from localStorage API to blob
      element.href = URL.createObjectURL(textFile);
      element.download = format === 'csv' ? "Report.csv" : "Report.txt";
      document.body.appendChild(element); 
      element.click();
    }
    
  }
  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <div>
        
        <div className="d-flex justify-content-center align-items-center mx-auto dropdown">
            <button className="w-75 h-40px btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {intl.formatMessage({id: 'Report'})}
            </button>
            <div className="dropdown-menu w-75" aria-labelledby="dropdownMenuButton">
                <a className="dropdown-item" onClick={() => getReport('json')}>JSON</a>
                <a className="dropdown-item" onClick={() => getReport('csv')}>CSV</a>
                <a className="dropdown-item" onClick={() => getReport('html')}>HTML</a>
            </div>
        </div>

      </div>
    </div>
  )
}

export {SidebarFooter}
