// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vulnerability_box_item:hover {
  border: 1px #ffffff82 solid;
}

.visible_icon {
  cursor: context-menu;
}

.pointer {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/vulnerabilities/components/vulnerability.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;AACJ;;AACA;EACI,oBAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ","sourcesContent":[".vulnerability_box_item:hover{\n    border: 1px #ffffff82 solid;\n}\n.visible_icon{\n    cursor: context-menu;\n}\n.pointer{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
