import {useState} from 'react'
import LastStatus from 'react-last-status'
import {useNavigate} from 'react-router-dom'
import Arr001 from '../../components/Arr001'

import {KTSVG} from '../../../../_metronic/helpers'
import './toltip.scss'

const colorConvertor = (grade) => {
  let color = ''
  color = grade === 'A' ? '#02694a' : color
  color = grade === 'B' ? '#255a8f' : color
  color = grade === 'C' ? '#9c8705' : color
  color = grade === 'D' ? '#75041e' : color
  color = grade === 'E' ? '#421919' : color
  color = grade === 'F' ? '#565672' : color

  return color
}

const NewTechnologies = ({assetData}) => {
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  return (
    <div className='w-100 h-100'>
      <div className={`card card-body h-100 bg-body rounded-bottom-0`}>
        {/* start title */}
        <div className='w-100 pe-3 d-flex h-100px flex-row justify-content-between align-items-start pt-2'>
          <h3 className=' '>Assets</h3>
          <input
            type='text'
            className='form-control w-100 mw-300px form-control-solid text-gray-700'
            placeholder='Search'
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {/* start body */}
        {/* start titles */}
        <div className='w-100 m-0 pe-3 '>
          <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
            <div className='w-200px p-1 h-auto'> Grade </div>
            <div className='w-100 p-1 ps-2 ms-5 h-auto'> Name </div>
            <div className='w-75 p-1 h-auto d-flex justify-content-center'>
              Total Vulnerabilities
            </div>
            <div className='w-100 d-flex justify-content-center p-1 ms-2 h-auto'>
              Vulnerabilities
            </div>
            <div className='w-100 p-1 ms-2 h-auto d-flex justify-content-center'> Last Seen </div>
            <div className='w-50 p-1 ms-2 h-auto d-flex justify-content-center pe-3'> View </div>
          </div>
        </div>
        {/* end titles */}
        <div className={`w-100 h-100 overflow-visible  pe-3 m-0 pb-8`}>
          {assetData
            .filter((it) => JSON.stringify(it).includes(search))
            ?.map((item, index) => {
              return (
                <div
                  key={item.id}
                  className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex justify-content-center align-items-center ${
                    index === assetData.length - 1 ? null : 'mb-3'
                  }`}
                >
                  <div className='w-100 d-flex p-2 pt-1 pb-1 justify-content-between align-items-center'>
                    <div className='w-200px d-flex justify-content-center align-items-center p-1 h-auto'>
                      <div
                        className={`d-flex justify-content-center align-items-center position-relative`}
                      >
                        <KTSVG
                          path='/media/hexagonal.svg'
                          className='svg-icon svg-icon-3x ms-2 svg-icon-body'
                          fill={colorConvertor(item.score)}
                        />
                        <span className='position-absolute ms-2 text-black fw-bolder'>
                          {item.score}
                        </span>
                      </div>
                    </div>
                    <div className='w-100 p-1 ps-2 ms-5 h-auto'> {item.host} </div>
                    <div className='w-75 p-1 h-auto d-flex justify-content-center'>
                      {item.total_vulnerabilities}
                    </div>
                    <div className='w-100 d-flex justify-content-center align-items-center p-1 h-auto'>
                      {/* critical */}
                      <div className='w-100 h-auto d-flex justify-content-center align-items-center '>
                        <div className='w-15px h-15px me-2 rounded-3 border-icritical border border-2 tooltip_tt'>
                          <span className='tooltiptext_tt'>critical</span>
                        </div>
                        {item.vulnerabilities.critical}
                      </div>
                      {/* high */}
                      <div className='w-100 h-auto d-flex justify-content-center align-items-center ms-2 '>
                        <div className='w-15px h-15px me-2 rounded-3 border-ihigh border border-2 tooltip_tt'>
                          <span class='tooltiptext_tt'>high</span>
                        </div>
                        {item.vulnerabilities.high}
                      </div>
                      {/* medium */}
                      <div className='w-100 h-auto d-flex justify-content-center align-items-center ms-2 '>
                        <div className='w-15px h-15px me-2 rounded-3 border-imedium border border-2 tooltip_tt'>
                          <span class='tooltiptext_tt'>medium</span>
                        </div>
                        {item.vulnerabilities.medium}
                      </div>
                      {/* low */}
                      <div className='w-100 h-auto d-flex justify-content-center align-items-center ms-2 '>
                        <div className='w-15px h-15px me-2 rounded-3 border-ilow border border-2 tooltip_tt'>
                          <span class='tooltiptext_tt'>low</span>
                        </div>
                        {item.vulnerabilities.low}
                      </div>
                      {/* info */}
                      <div className='w-100 h-auto d-flex justify-content-center align-items-center ms-2 '>
                        <div className='w-15px h-15px me-2 rounded-3 border-iinfo border border-2  tooltip_tt'>
                          <span class='tooltiptext_tt'>info</span>
                        </div>
                        {item.vulnerabilities.info}
                      </div>
                    </div>
                    <div className='w-100 p-1 ms-2 h-auto d-flex justify-content-center'>
                      <LastStatus
                        timestamp={item.timestamp}
                        // now={'[I am alive]'}
                        // sameDay={'[last seen today at] HH:mm'}
                        // lastDay={'[last seen yesterday at] HH:mm'}
                        // lastWeek={'[last seen] dddd [at] HH:mm'}
                        // sameElse={'[last seen] L [at] HH:mm'}
                      />
                    </div>
                    <div className='w-50 p-1 ms-2 h-auto d-flex justify-content-center pe-3'>
                      <button
                        className={`col-auto border border-0  bg-gray-200 p-0 m-0`}
                        onClick={() => {
                          navigate({
                            pathname: '/asset-details',
                            search: `?id=${item.id}`,
                          })
                        }}
                      >
                        {/* <KTSVG
                          path='/media/icons/duotune/arrows/arr001.svg'
                          className='svg-icon svg-icon-2x ms-2 svg-icon-white'
                        /> */}
                        <Arr001 fill={'#fff'} className={'image_change_theme'} />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>

        <div className={`row d-flex flex-row position-absolute bottom-0 my-2`}>
          <div
            className={`w-auto h-auto  ms-2 me-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-iinfo border border-2' />
            info
          </div>
          <div
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-ilow border border-2' />
            low
          </div>
          <div
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-imedium border border-2' />
            medium
          </div>
          <div
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-ihigh border border-2' />
            high
          </div>
          <div
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-icritical border border-2' />
            critical
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewTechnologies
