import React, {memo, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import DatePicker from 'react-multi-date-picker'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import axios from 'axios'
const timezoneRegex = /([A-Z]+[\+-][0-9]+)/
const DomainRegex =
  /^(((http|https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/
interface DAGS {
  id: string | number
  description: undefined | string | null
  name?: string | null | undefined
}
const initialValues = {
  target_domain: '',
  target_description: '',
  dag_id: '',
  time: '',
  start_time: '',
}
const loginSchema = Yup.object().shape({
  target_domain: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Target is required')
    .matches(DomainRegex, 'Domain is not valid'),
  target_description: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Description is required'),
  time: Yup.string().required('Rate is required'),
  start_time: Yup.string().required('Schedule is required'),
})
function getTimezone () {
  const timezone = new Date()?.toString()
  const test: any[] | null = timezone.match(timezoneRegex)
  if (test && test[0]) return test[0]
}

const CreateModal = memo(
  ({
    dags,
    getData,
    setShow,
    setErrShow,
    setLoading,
  }: {
    dags: DAGS[]
    getData: (page?: number) => Promise<void>
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    setErrShow: React.Dispatch<React.SetStateAction<boolean>>
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  }) => {
    const [page, setPage] = useState(1)
    const [workflowId, setWorkflowId] = useState<null | number | string>(null)
    const [start, setStart] = useState<any>(null)
    const [target, setTarget] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [check, setCheck] = useState(0)
    function clearForm () {
      formik.resetForm()
      setWorkflowId(null)
      setStart(null)
      setPage(1)
      setTarget('')
      setDescription('')
      setCheck(0)
      setLoading(false)
    }
    const formik = useFormik({
      initialValues,
      validationSchema: loginSchema,
      onSubmit: (values) => {
        setLoading(true)
        axios
          .post(`${process.env.REACT_APP_API_ENDPOINT}/scan/`, {
            ...values,
            start_time: start
              ? start === ' '
                ? null
                : `${start?.year}-${start?.month}-${start?.day}T${start?.hour}:${start?.minute}:${start?.second}`
              : null,
          })
          .then((res) => {
            clearForm()
            setShow(true)
            getData()
          })
          .catch((e) => {
            console.error(e)
            console.log('message \n', e?.response?.data?.error)
            clearForm()
            setErrShow(e?.response?.data?.error)
            setLoading(false)
          })
      },
    })

    return (
      <>
        <div
          className='modal fade '
          id='kt_modal_1'
          style={{backdropFilter: 'blur(3px)'}}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            clearForm()
          }}
        >
          <div
            className='modal-dialog'
            style={{minWidth: '50%'}}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <h3 className='modal-title'>Create New Scan</h3>

                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={clearForm}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon-2 image_change_theme2'
                  />
                </div>
              </div>

              <div className='modal-body'>
                <form
                  className='w-100 form'
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  {page === 1 && (
                    <>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder  '>Target</label>
                        <input
                          placeholder='example.com'
                          {...formik.getFieldProps('target_domain')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                              'is-invalid':
                                formik.touched.target_domain && formik.errors.target_domain,
                            },
                            {
                              'is-valid':
                                formik.touched.target_domain && !formik.errors.target_domain,
                            }
                          )}
                          type='text'
                          name='target_domain'
                          autoComplete='off'
                          value={target}
                          onChange={(e) => {
                            setTarget(e.target.value)
                            formik.setFieldValue('target_domain', e.target.value)
                          }}
                        />
                        {formik.touched.target_domain && formik.errors.target_domain && (
                          <div className='fv-plugins-message-container mt-2 text-danger'>
                            <span role='alert'>{formik.errors.target_domain}</span>
                          </div>
                        )}
                      </div>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder'>Description</label>
                        <textarea
                          placeholder='Description'
                          {...formik.getFieldProps('target_description')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                              'is-invalid':
                                formik.touched.target_description &&
                                formik.errors.target_description,
                            },
                            {
                              'is-valid':
                                formik.touched.target_description &&
                                !formik.errors.target_description,
                            }
                          )}
                          name='target_description'
                          autoComplete='off'
                          value={description}
                          onChange={(e) => {
                            setDescription(e.target.value)
                            formik.setFieldValue('target_description', e.target.value)
                          }}
                        />
                        {formik.touched.target_description && formik.errors.target_description && (
                          <div className='fv-plugins-message-container mt-2 text-danger'>
                            <span role='alert'>{formik.errors.target_description}</span>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {page === 2 && (
                    <>
                      <>
                        <Link to={'/workFlow'}>
                          <button
                            type='button'
                            className='btn btn-info mb-5'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          >
                            Create workflow
                          </button>
                        </Link>
                      </>
                      <div style={{maxHeight: '60vh', overflow: 'auto'}}>
                        {dags?.map((item, ind) => (
                          <div
                            key={ind}
                            onClick={() => {
                              setWorkflowId(item.id)
                              formik.setFieldValue('dag_id', item.id)
                            }}
                            className='card w-100 p-4 bg-gray-400 hover mt-4'
                            style={workflowId === item.id ? {border: '1px solid #3cf03c'} : {}}
                          >
                            <div className='d-flex justify-content-between text-capitalize'>
                              <h2>{item.description}</h2>
                              <div
                                className='d-flex gap-3'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                              >
                                <Link to={'/workFlow'}>
                                  <KTSVG
                                    path='/media/visibel.svg'
                                    className='svg-icon svg-icon-2x ms-2 svg-icon-white'
                                  />
                                </Link>
                              </div>
                            </div>
                            <p>
                              {item.description === 'passive scan'
                                ? 'Enumerate all subdomains and check vulnerabilities using passive scan'
                                : item.description === 'active scan'
                                ? 'Enumerate all subdomains and check vulnerabilities using active scan. this workflow could take a while'
                                : 'Enumerate all subdomains and asset'}
                            </p>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {page === 3 && (
                    <>
                      <h3 style={{marginBottom: 30}}>{getTimezone()}</h3>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder'>Rate</label>
                        <select
                          className={`form-select form-select-solid ${clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.time && formik.errors.time},
                            {
                              'is-valid': formik.touched.time && !formik.errors.time,
                            }
                          )}`}
                          data-kt-select2='true'
                          data-placeholder='Severity Filter'
                          data-allow-clear='true'
                          placeholder='Time'
                          {...formik.getFieldProps('time')}
                          name='time'
                          // onChange={(e) => {
                          //   setTime(e.target.value)
                          // }}
                          // value={time}
                        >
                          <option></option>
                          <option value='once'>Once</option>
                          <option value='daily'>Daily</option>
                          <option value='weekly'>Weekly</option>
                          <option value='monthly'>Monthly </option>
                        </select>
                        {formik.touched.time && formik.errors.time && (
                          <div className='fv-plugins-message-container mt-2 text-danger'>
                            <span role='alert'>{formik.errors.time}</span>
                          </div>
                        )}
                      </div>
                      {!!!check && (
                        <div className='fv-row mb-10'>
                          <label className='form-label fs-6 fw-bolder d-block'>Schedule</label>
                          <DatePicker
                            format='YYYY/MM/DD HH:mm'
                            className='bg-dark'
                            value={start}
                            plugins={[<TimePicker position='bottom' />]}
                            render={
                              <input
                                placeholder='Schedule'
                                readOnly
                                {...formik.getFieldProps('start_time')}
                                className={clsx(
                                  'form-control form-control-lg form-control-solid',
                                  {
                                    'is-invalid':
                                      formik.touched.start_time && formik.errors.start_time,
                                  },
                                  {
                                    'is-valid':
                                      formik.touched.start_time && !formik.errors.start_time,
                                  }
                                )}
                                type='text'
                                name='start_time'
                                autoComplete='off'
                              />
                            }
                            onChange={(e) => {
                              formik.setFieldValue('start_time', e)
                              setStart(e)
                            }}
                          />
                          {formik.touched.start_time && formik.errors.start_time && (
                            <div className='fv-plugins-message-container mt-2 text-danger '>
                              <span role='alert'>{formik.errors.start_time}</span>
                            </div>
                          )}
                        </div>
                      )}
                      <div className='form-check form-check-custom form-check-solid  mb-10'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value={check}
                          onChange={(e) => {
                            if (check === 1) {
                              formik.setFieldValue('start_time', '')
                              setCheck(0)
                              setStart('')
                            } else {
                              formik.setFieldValue('start_time', ' ')
                              setCheck(1)
                              setStart(' ')
                            }
                          }}
                          id='flexCheckChecked'
                        />
                        <label className='form-label fs-6 fw-bolder   d-block ms-3 mb-0'>
                          Start Instantly
                        </label>
                      </div>
                      <div className='d-flex gap-5'>
                        <button
                          type='submit'
                          id='kt_sign_in_submit'
                          className='btn btn-success'
                          disabled={formik.isSubmitting || !formik.isValid || !!check}
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        >
                          <span
                            className='indicator-label'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          >
                            Save
                          </span>
                        </button>
                        <button
                          type='submit'
                          id='kt_sign_in_submit'
                          className='btn btn-success'
                          disabled={formik.isSubmitting || !formik.isValid || !check}
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        >
                          <span
                            className='indicator-label'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          >
                            Save & Start
                          </span>
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </div>

              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-info'
                  onClick={() => {
                    setPage(page - 1)
                  }}
                  style={page <= 1 ? {display: 'none'} : {display: 'flex'}}
                >
                  Back
                </button>
                <button
                  type='button'
                  className='btn btn-info'
                  onClick={() => {
                    setPage(page + 1)
                  }}
                  style={page >= 3 ? {display: 'none'} : {display: 'flex'}}
                  disabled={
                    (page === 2 && !workflowId) ||
                    (page === 1 && (!DomainRegex.test(target) || description?.length < 3))
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
)
export default CreateModal
