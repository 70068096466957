/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts from 'apexcharts'
// import { ApexOptions }  from 'apexcharts'

const getAmount = (percent)=>{
  switch (true) {
    case (percent == 'A'):
      return 90
    case (percent == 'B'):
      return 70
    case (percent == 'C'):
      return 50
    case (percent == 'D'):
      return 30
    default:
      return 10
  }
}
const getColor = (percent) => {
  switch (true) {
    case (percent == 'A'):
      return '#008000'
    case (percent == 'B'):
      return '#0000FF'
    case (percent == 'C'):
      return '#FFFF00'
    case (percent == 'D'):
      return '#FF7F7F'
    default:
      return '#FF0000'
  }
}

const HalfCircleChart = ({title = 'Total Grade', percent = 'B'}) => {
  const chartRef = useRef(null)
  const color = getColor(percent)
  const amount = getAmount(percent)
  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, option2)
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  const option2 = {
    chart: {
      type: 'radialBar',
      height: 250,
      width: 250,
      offsetY: -20,
      offsetX: -50,
    },
    series: [amount],
    colors: [color],
    labels: [title],
    stroke: {
      lineCap: 'round',
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,

        dataLabels: {
          show: true,
          name: {
            offsetY: 10,
            show: true,
            color: 'var(--bs-gray-700)',
            fontSize: '12px',
          },
          value: {
            formatter: function (val) {
              return percent
              // return grade
            },
            offsetY: -30,
            color: 'var(--bs-gray-900)',
            fontSize: '24px',
            fontWeight: 'bold',
            show: true,
          },
        },

        track: {
          background: 'var(--bs-gray-500)',
          strokeWidth: '85%',
        },
      },
    },
    responsive: [
      {
        breakpoint: 1300,
        options: {
          chart: {
            height: 150,
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                show: true,
                name: {
                  offsetY: 10,
                  fontSize: '10px',
                },
                value: {
                  formatter: function (val) {
                    return percent
                    // return parseInt(val)
                  },
                  offsetY: -25,
                  fontSize: '14px',
                },
              },
            },
          },
        },
      },
    ],
  }

  // const options1 = {
  //   series: [70],
  //   colors: ['#ff0050'],
  //   chart: {
  //     lineCap: 'round',
  //     width: '100%',
  //     type: 'radialBar',
  //     offsetY: -20,
  //   },
  //   stroke: {
  //     lineCap: 'round',
  //   },
  //   plotOptions: {
  //     radialBar: {
  //       startAngle: -90,
  //       endAngle: 90,
  //       track: {
  //         background: '#2B2B40',
  //         strokeWidth: '97%',
  //         //  margin: 5, // margin is in pixels
  //         //  dropShadow: {
  //         //    enabled: true,
  //         //    top: 2,
  //         //    left: 0,
  //         //    color: '#999',
  //         //    opacity: 1,
  //         //    blur: 2,
  //         //  },
  //       },
  //       dataLabels: {
  //         name: {
  //           show: false,
  //         },
  //         value: {
  //           offsetY: -18,
  //           fontSize: '20px',
  //           formatter: function (val) {
  //             return val
  //           },
  //         },
  //       },
  //     },
  //   },
  //   //  fill: {
  //   //    type: 'gradient',
  //   //    gradient: {
  //   //      shade: 'light',
  //   //      shadeIntensity: 0.4,
  //   //      inverseColors: false,
  //   //      opacityFrom: 1,
  //   //      opacityTo: 1,
  //   //      stops: [0, 50, 53, 91],
  //   //    },
  //   //  },
  //   //  labels: ['Average Results'],
  // }

  return (
    <div className='position-relative'>
      <div ref={chartRef} id='chart1' />
    </div>
  )
}

export default HalfCircleChart
